import { breakpoints, colors, elements, spacing } from '@hp/theme';
import React from 'react';
import styled, { css } from 'styled-components';

import { hasFeature } from '../../utils';
import { Corner } from './icons/Corner';

export type NoteProps = {
  className?: string;
  marginSize?: Extract<keyof typeof spacing, 'm' | 'l'>;
  disablePaddingTop?: boolean;
  backgroundColor?: string;
};

type NoteStyledProps = Pick<
  NoteProps,
  'marginSize' | 'disablePaddingTop' | 'backgroundColor'
>;

const elementCornerSize = 18;

const NoteStyledV2 = styled.div<NoteStyledProps>`
  position: relative;
  padding: ${({ disablePaddingTop }) =>
      disablePaddingTop ? '1px' : `${spacing.m}`}
    ${spacing.s} ${spacing.m} ${spacing.s};
  ${({ marginSize }) =>
    marginSize
      ? css`
          margin-top: ${spacing[marginSize]};
          margin-bottom: ${spacing[marginSize]};
        `
      : ''};
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : colors.note};
  border-radius: 0 ${elementCornerSize * 2}px 0 0;
  width: 100%;

  @media (min-width: ${breakpoints.minDesktop}) {
    max-width: ${elements.innerElementMaxWidth};
  }
`;

const NoteStyled = styled.div<NoteStyledProps>`
  position: relative;
  padding: ${({ disablePaddingTop }) =>
      disablePaddingTop ? spacing.xxs : spacing.m}
    ${spacing.s} ${spacing.xxs} ${spacing.s};
  ${({ marginSize }) =>
    marginSize
      ? css`
          margin-top: ${spacing[marginSize]};
          margin-bottom: ${spacing[marginSize]};
        `
      : ''};
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : colors.gray_superlight};
  border-radius: 5px;
  width: 100%;

  @media (min-width: ${breakpoints.minDesktop}) {
    max-width: ${elements.innerElementMaxWidth};
  }
`;

const CornerStyled = styled(Corner)`
  position: absolute;
  right: 0;
  top: 0;
`;

const CornerStyledOverlay1 = styled(Corner)`
  position: absolute;
  right: ${elementCornerSize}px;
  top: 0;
`;

const CornerStyledOverlay2 = styled(Corner)`
  position: absolute;
  right: 0;
  top: ${elementCornerSize}px;
`;

export const Note: React.FC<NoteProps> = ({
  children,
  marginSize,
  disablePaddingTop = true,
  ...props
}) =>
  hasFeature('v3') ? (
    <NoteStyled
      marginSize={marginSize}
      disablePaddingTop={disablePaddingTop}
      {...props}
    >
      {children}
    </NoteStyled>
  ) : (
    <NoteStyledV2
      marginSize={marginSize}
      disablePaddingTop={disablePaddingTop}
      {...props}
    >
      {children}
      <CornerStyled backgroundColor="transparent" />
      <CornerStyledOverlay1 backgroundColor={colors.note} color="transparent" />
      <CornerStyledOverlay2 backgroundColor={colors.note} color="transparent" />
    </NoteStyledV2>
  );

export default Note;
