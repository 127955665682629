import { colors } from '@hp/theme';
import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

export const CourierSmallIcon: FC<Props> = ({
  className,
  color = colors.white,
}) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      r="2"
      transform="matrix(-1 0 0 1 5.09961 14.5)"
      stroke={color}
      strokeLinecap="round"
    />
    <circle
      r="2"
      transform="matrix(-1 0 0 1 14.0996 14.5)"
      stroke={color}
      strokeLinecap="round"
    />
    <path d="M7.5 14.5L12 14.5" stroke={color} strokeLinecap="round" />
    <path
      d="M16.5 14.5H18.5V10L15 6.5H13.5L13.5 12.5"
      stroke={color}
      strokeLinecap="round"
    />
    <path
      d="M13.5 6.68421V3.5H1.5L1.5 14.5H3"
      stroke={color}
      strokeLinecap="round"
    />
    <path d="M4 8.5L11 8.5" stroke={color} strokeLinecap="round" />
    <path d="M9 6.5L11 8.5L9 10.5" stroke={color} strokeLinecap="round" />
  </svg>
);
