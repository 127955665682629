import { colors } from '@hp/theme';
import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

export const MapSearchIcon: FC<Props> = ({
  className,
  color = colors.red_dark,
}) => (
  <svg
    className={className}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 3.42857L12.3124 2.9651L12 3.09155V3.42857H12.5ZM18.5 1H19C19 0.833725 18.9173 0.678329 18.7795 0.585393C18.6416 0.492457 18.4665 0.474142 18.3124 0.536527L18.5 1ZM18 10.2857C18 10.5619 18.2239 10.7857 18.5 10.7857C18.7761 10.7857 19 10.5619 19 10.2857H18ZM12 9.71429C12 9.99043 12.2239 10.2143 12.5 10.2143C12.7761 10.2143 13 9.99043 13 9.71429H12ZM12.6876 3.89204L18.6876 1.46347L18.3124 0.536527L12.3124 2.9651L12.6876 3.89204ZM18 1V10.2857H19V1H18ZM13 9.71429V3.42857H12V9.71429H13Z"
      fill={color}
    />
    <path
      d="M0.5 3.42857L0.312404 2.9651L0 3.09155V3.42857H0.5ZM6.5 1H7C7 0.833725 6.91734 0.678329 6.77947 0.585393C6.64159 0.492457 6.46653 0.474142 6.3124 0.536527L6.5 1ZM6.5 15.5714L6.6876 16.0349L7 15.9085V15.5714H6.5ZM0.5 18H0C0 18.1663 0.0826573 18.3217 0.220535 18.4146C0.358412 18.5075 0.533469 18.5259 0.687596 18.4635L0.5 18ZM0.687596 3.89204L6.6876 1.46347L6.3124 0.536527L0.312404 2.9651L0.687596 3.89204ZM6 1V15.5714H7V1H6ZM6.3124 15.108L0.312404 17.5365L0.687596 18.4635L6.6876 16.0349L6.3124 15.108ZM1 18V3.42857H0V18H1Z"
      fill={color}
    />
    <path
      d="M12.5 9.71429V3.42857L6.5 1V15.5714L9.5 16.7857L10.25 17.0893"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="15" cy="14.5" r="3.5" stroke={color} strokeLinecap="round" />
    <path d="M17.5 17L20 19.5" stroke={color} strokeLinecap="round" />
  </svg>
);
