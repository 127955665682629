import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const Axes3DIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.5 12.5V3.5" stroke="#414042" strokeLinecap="round" />
    <path d="M2.5 16.5L9.5 12.5" stroke="#414042" strokeLinecap="round" />
    <path d="M16.5 16.5L9.5 12.5" stroke="#414042" strokeLinecap="round" />
    <path
      d="M3.23208 15L2 16.866L4.23205 16.7321"
      stroke="#414042"
      strokeLinecap="round"
    />
    <path d="M8.5 5L9.5 3L10.5 5" stroke="#414042" strokeLinecap="round" />
    <path
      d="M16.0005 15.0619L17.2969 16.8838L15.0616 16.8278"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect x="16" y="2" width="1" height="1" fill="#DC0032" />
    <rect x="15" y="4" width="2" height="1" fill="#DC0032" />
    <rect x="15" y="8" width="3" height="1" fill="#DC0032" />
    <rect x="16" y="5" width="1" height="3" fill="#DC0032" />
  </svg>
);
