import { colors } from '@hp/theme';
import React, { FC } from 'react';

type Props = {
  className?: string;
  disabled?: boolean;
};

export const LockerIcon: FC<Props> = ({ className, disabled }) => {
  const color1 = disabled ? colors.gray_badge : colors.black;
  const color2 = disabled ? colors.gray_mid : colors.red_main;
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.5 1.5C0.5 0.947715 0.947715 0.5 1.5 0.5H18.5C19.0523 0.5 19.5 0.947715 19.5 1.5V13.5H0.5V1.5Z"
        stroke={color1}
        strokeLinecap="round"
      />
      <path d="M7 0.5V13.1623" stroke={color1} strokeLinecap="round" />
      <rect
        x="9"
        y="3.5"
        width="2"
        height="3"
        stroke={color2}
        strokeLinecap="round"
      />
      <path d="M13 0.5V13.1623" stroke={color1} strokeLinecap="round" />
      <path d="M1 9.5H7" stroke={color1} strokeLinecap="round" />
      <path d="M13 9.5H19" stroke={color1} strokeLinecap="round" />
      <path d="M1 4.5H7" stroke={color1} strokeLinecap="round" />
      <path d="M13 4.5H19" stroke={color1} strokeLinecap="round" />
      <path d="M9.13623 9.5H10.8635" stroke={color2} strokeLinecap="round" />
    </svg>
  );
};
