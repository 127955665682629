import { colors, spacing } from '@hp/theme';
import styled, { css } from 'styled-components';

type HrMarginSize = Extract<
  keyof typeof spacing,
  'zero' | 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl'
>;

type HrProps = {
  noMargin?: boolean;
  marginSize?: HrMarginSize;
};

const hrMarginCss = css<HrProps>`
  margin: 0 0 ${spacing.m} 0;

  ${({ marginSize }) =>
    marginSize &&
    css`
      margin: ${spacing[marginSize]} 0;
    `};

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0;
    `};
`;

export const Hr = styled.div<HrProps>`
  border-top: 1px solid ${colors.gray_light};
  width: 100%;
  height: 1px;
  margin: 0 0 ${({ noMargin }) => (noMargin ? '0' : spacing.m)} 0;
  padding: 0;
  ${hrMarginCss};
`;
