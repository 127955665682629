import { breakpoints, typography } from '@hp/theme';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

export enum TypographyType {
  BodyMicro = 'BodyMicro',
  BodyMicroLight = 'BodyMicroLight',
  BodySmall = 'BodySmall',
  BodySmallLight = 'BodySmallLight',
  BodyRegular = 'BodyRegular',
  BodyLight = 'BodyLight',
  BodyExtraLargeLight = 'BodyExtraLargeLight',
  Heading1Regular = 'Heading1Regular',
  Heading1Light = 'Heading1Light',
  Heading1ExtraLight = 'Heading1ExtraLight',
  Heading2Regular = 'Heading2Regular',
  Heading2Light = 'Heading2Light',
}

export type TypographyProps = {
  className?: string;
  type: TypographyType;
  color?: string;
  textAlign?: 'center' | 'left' | 'right';
  wordBreak?: 'normal' | 'break-word';
  fullWidth?: boolean;
};

type StyledTypography = Pick<
  TypographyProps,
  'color' | 'textAlign' | 'wordBreak' | 'fullWidth'
>;

const CommonCss = css<StyledTypography>`
  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};
  word-break: ${({ wordBreak }) => wordBreak};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      display: inline-block;
      width: 100%;
    `};
`;

const BodyMicro = styled.span<StyledTypography>`
  ${CommonCss};
  font-size: ${typography.fontSize.bodyMicro};
  line-height: ${typography.lineHeight.bodyMicro};
  font-weight: ${typography.fontWeight.normal};
`;

const BodyMicroLight = styled.span<StyledTypography>`
  ${CommonCss};
  font-size: ${typography.fontSize.bodyMicro};
  line-height: ${typography.lineHeight.bodyMicro};
  font-weight: ${typography.fontWeight.light};
`;

const BodySmall = styled.span<StyledTypography>`
  ${CommonCss};
  font-size: ${typography.fontSize.bodySmall};
  line-height: ${typography.lineHeight.bodySmall};
  font-weight: ${typography.fontWeight.normal};
`;

const BodySmallLight = styled.span<StyledTypography>`
  ${CommonCss};
  font-size: ${typography.fontSize.bodySmall};
  line-height: ${typography.lineHeight.bodySmall};
  font-weight: ${typography.fontWeight.light};
`;

const BodyRegular = styled.span<StyledTypography>`
  ${CommonCss};
  font-size: ${typography.fontSize.body};
  line-height: ${typography.lineHeight.body};
  font-weight: ${typography.fontWeight.normal};
`;

const BodyLight = styled.span<StyledTypography>`
  ${CommonCss};
  font-size: ${typography.fontSize.body};
  line-height: ${typography.lineHeight.body};
  font-weight: ${typography.fontWeight.light};
`;

const BodyExtraLargeLight = styled.span<StyledTypography>`
  ${CommonCss};
  font-size: ${typography.fontSize.bodyExtraLarge};
  line-height: ${typography.lineHeight.body};
  font-weight: ${typography.fontWeight.light};
`;

const Heading1Regular = styled.span<StyledTypography>`
  ${CommonCss};
  font-size: ${typography.fontSize.h1};
  line-height: ${typography.lineHeight.h1};
  font-weight: ${typography.fontWeight.normal};

  @media (min-width: ${breakpoints.minDesktop}) {
    font-size: ${typography.fontSize.desktop.h1};
    line-height: ${typography.lineHeight.desktop.h1};
  }
`;

const Heading1Light = styled.span<StyledTypography>`
  ${CommonCss};
  font-size: ${typography.fontSize.h1};
  line-height: ${typography.lineHeight.h1};
  font-weight: ${typography.fontWeight.light};

  @media (min-width: ${breakpoints.minDesktop}) {
    font-size: ${typography.fontSize.desktop.h1};
    line-height: ${typography.lineHeight.desktop.h1};
  }
`;

const Heading1ExtraLight = styled.span<StyledTypography>`
  ${CommonCss};
  font-size: ${typography.fontSize.h1};
  line-height: ${typography.lineHeight.h1};
  font-weight: ${typography.fontWeight.extraLight};

  @media (min-width: ${breakpoints.minDesktop}) {
    font-size: ${typography.fontSize.desktop.h1};
    line-height: ${typography.lineHeight.desktop.h1};
  }
`;

const Heading2Regular = styled.span<StyledTypography>`
  ${CommonCss};
  font-size: ${typography.fontSize.h2};
  line-height: ${typography.lineHeight.h2};
  font-weight: ${typography.fontWeight.normal};

  @media (min-width: ${breakpoints.minDesktop}) {
    font-size: ${typography.fontSize.desktop.h2};
    line-height: ${typography.lineHeight.desktop.h2};
  }
`;

const Heading2Light = styled.span<StyledTypography>`
  ${CommonCss};
  font-size: ${typography.fontSize.h2};
  line-height: ${typography.lineHeight.h2};
  font-weight: ${typography.fontWeight.light};

  @media (min-width: ${breakpoints.minDesktop}) {
    font-size: ${typography.fontSize.desktop.h2};
    line-height: ${typography.lineHeight.desktop.h2};
  }
`;

export const Typography: FC<TypographyProps> = ({
  className,
  children,
  type,
  color,
  textAlign = 'left',
  wordBreak = 'normal',
  fullWidth = false,
}) => {
  const commonProps = { className, color, textAlign, wordBreak, fullWidth };

  return ((type) => {
    switch (type) {
      case TypographyType.BodyMicro:
        return <BodyMicro {...commonProps}>{children}</BodyMicro>;
      case TypographyType.BodyMicroLight:
        return <BodyMicroLight {...commonProps}>{children}</BodyMicroLight>;
      case TypographyType.BodySmall:
        return <BodySmall {...commonProps}>{children}</BodySmall>;
      case TypographyType.BodySmallLight:
        return <BodySmallLight {...commonProps}>{children}</BodySmallLight>;
      case TypographyType.BodyLight:
        return <BodyLight {...commonProps}>{children}</BodyLight>;
        return <BodyLight {...commonProps}>{children}</BodyLight>;
      case TypographyType.BodyExtraLargeLight:
        return (
          <BodyExtraLargeLight {...commonProps}>{children}</BodyExtraLargeLight>
        );
      case TypographyType.Heading1Regular:
        return <Heading1Regular {...commonProps}>{children}</Heading1Regular>;
      case TypographyType.Heading1Light:
        return <Heading1Light {...commonProps}>{children}</Heading1Light>;
      case TypographyType.Heading1ExtraLight:
        return (
          <Heading1ExtraLight {...commonProps}>{children}</Heading1ExtraLight>
        );
      case TypographyType.Heading2Regular:
        return <Heading2Regular {...commonProps}>{children}</Heading2Regular>;
      case TypographyType.Heading2Light:
        return <Heading2Light {...commonProps}>{children}</Heading2Light>;
      case TypographyType.BodyRegular:
      default:
        return <BodyRegular {...commonProps}>{children}</BodyRegular>;
    }
  })(type);
};
