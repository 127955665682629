import { colors } from '@hp/theme';
import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

export const PlaceIcon: FC<Props> = ({ className, color = colors.black }) => (
  <svg
    width="18"
    height="24"
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <ellipse
      cx="8.99998"
      cy="8.99998"
      rx="2.4"
      ry="2.4"
      stroke={color}
      strokeLinecap="round"
    />
    <path
      d="M17.4 9.28569C17.4 15.8 8.99998 23.4 8.99998 23.4C8.99998 23.4 0.599976 15.8 0.599976 9.28569C0.599976 4.4887 4.36078 0.599976 8.99998 0.599976C13.6392 0.599976 17.4 4.4887 17.4 9.28569Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
