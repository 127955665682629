import { colors } from '@hp/theme';
import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

export const CourierCheckedIcon: FC<Props> = ({
  className,
  color = colors.green,
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle
      r="2"
      transform="matrix(-1 0 0 1 5.1001 14.5)"
      stroke={color}
      strokeLinecap="round"
    />
    <circle
      r="2"
      transform="matrix(-1 0 0 1 14.1001 14.5)"
      stroke={color}
      strokeLinecap="round"
    />
    <path d="M7.5 14.5L12 14.5" stroke="#66A821" strokeLinecap="round" />
    <path
      d="M16.5 14.5H18.5V10L15 6.5H13.5L13.5 12.5"
      stroke={color}
      strokeLinecap="round"
    />
    <path
      d="M13.5 6.68421V3.5H1.5L1.5 14.5H3"
      stroke={color}
      strokeLinecap="round"
    />
    <path d="M10 6L6 10L4 8" stroke="#66A821" strokeLinecap="round" />
  </svg>
);
