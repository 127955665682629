import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

export const ParcelShopIcon: FC<Props> = ({ className, color }) => (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M20.4784 7.50001C20.4784 7.50001 20.4784 13.0942 20.4784 17.5H17.4998V9.5H13.5219V17.5H1.49982C1.49982 17.5 1.49982 11.8467 1.49982 7.5"
      stroke={color ? color : '#414042'}
      strokeLinecap="round"
    />
    <path
      d="M5.50012 9.5H10.5001V14.5H5.50012V9.5Z"
      stroke={color ? color : '#DC0032'}
      strokeLinecap="round"
    />
    <path
      d="M3.50012 3.08691L0.500122 5.86952H21.5001L18.5001 3.08691H3.50012Z"
      stroke={color ? color : '#414042'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.5001 3V1H3.50012V3"
      stroke={color ? color : '#414042'}
      strokeLinecap="round"
    />
  </svg>

  // <svg
  //   className={className}
  //   width="32"
  //   height="32"
  //   viewBox="0 0 32 32"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <path
  //     d="M27.3717 26.353H5.72467V12.7059C5.72467 12.4457 5.93502 12.2354 6.19526 12.2354C6.45549 12.2354 6.66585 12.4457 6.66585 12.7059V25.4118H26.4306V12.7059C26.4306 12.4457 26.6409 12.2354 26.9011 12.2354C27.1614 12.2354 27.3717 12.4457 27.3717 12.7059V26.353Z"
  //     fill={color ? color : '#414042'}
  //   />
  //   <path
  //     d="M12.3129 20.7059H8.54822V15.0786H12.3129V20.7059ZM9.48939 19.7647H11.3717V16.0198H9.48939V19.7647Z"
  //     fill={color ? color : '#414042'}
  //   />
  //   <path
  //     d="M17.0188 20.7059H13.2541V15.0786H17.0188V20.7059ZM14.1953 19.7647H16.0776V16.0198H14.1953V19.7647Z"
  //     fill={color ? color : '#414042'}
  //   />
  //   <path
  //     d="M24.0777 24.4706C23.8174 24.4706 23.6071 24.2603 23.6071 24V16H19.8424V24C19.8424 24.2603 19.632 24.4706 19.3718 24.4706C19.1115 24.4706 18.9012 24.2603 18.9012 24V15.0588H24.5482V24C24.5482 24.2603 24.3374 24.4706 24.0777 24.4706Z"
  //     fill={color ? color : '#414042'}
  //   />
  //   <path
  //     d="M24.8376 12.2353C23.984 12.2353 23.2263 11.8122 22.7652 11.1647C22.3035 11.8122 21.5468 12.2353 20.6927 12.2353C19.8386 12.2353 19.0819 11.8122 18.6207 11.1647C18.1595 11.8122 17.4019 12.2353 16.5482 12.2353C15.6946 12.2353 14.9374 11.8122 14.4762 11.1647C14.0146 11.8122 13.2579 12.2353 12.4038 12.2353C11.5496 12.2353 10.7925 11.8122 10.3313 11.1647C9.86964 11.8122 9.11294 12.2353 8.25882 12.2353C6.94164 12.2353 5.85599 11.2287 5.72847 9.94495C5.72658 9.93224 5.72564 9.91907 5.7247 9.90589C5.71905 9.83577 5.71576 9.76424 5.71576 9.69271C5.71576 9.6626 5.71858 9.63295 5.72423 9.60424V8.00001C5.72423 7.73977 5.93458 7.52942 6.19482 7.52942C6.45505 7.52942 6.66541 7.73977 6.66541 8.00001V9.85601C6.74823 10.6626 7.43105 11.2941 8.25882 11.2941C9.14211 11.2941 9.86023 10.576 9.86023 9.69271C9.86023 9.43248 10.0706 9.22213 10.3308 9.22213C10.5911 9.22213 10.8014 9.43248 10.8014 9.69271C10.8014 10.576 11.52 11.2941 12.4033 11.2941C13.2866 11.2941 14.0047 10.576 14.0047 9.69271C14.0047 9.43248 14.2151 9.22213 14.4753 9.22213C14.7355 9.22213 14.9459 9.43248 14.9459 9.69271C14.9459 10.576 15.664 11.2941 16.5473 11.2941C17.4306 11.2941 18.1492 10.576 18.1492 9.69271C18.1492 9.43248 18.3595 9.22213 18.6198 9.22213C18.88 9.22213 19.0903 9.43248 19.0903 9.69271C19.0903 10.576 19.8085 11.2941 20.6918 11.2941C21.5751 11.2941 22.2932 10.576 22.2932 9.69271C22.2932 9.43248 22.5035 9.22213 22.7638 9.22213C23.024 9.22213 23.2343 9.43248 23.2343 9.69271C23.2343 10.576 23.9529 11.2941 24.8362 11.2941C25.6626 11.2941 26.3449 10.6649 26.4292 9.86024V8.00001C26.4292 7.73977 26.6395 7.52942 26.8998 7.52942C27.16 7.52942 27.3703 7.73977 27.3703 8.00001V9.60189C27.376 9.63154 27.3793 9.66165 27.3793 9.69224C27.3802 11.0946 26.2395 12.2353 24.8376 12.2353Z"
  //     fill={color ? color : '#DC0032'}
  //   />
  //   <path
  //     d="M26.9011 6.58827H6.19526C5.93502 6.58827 5.72467 6.37792 5.72467 6.11768C5.72467 5.85745 5.93502 5.64709 6.19526 5.64709H26.9011C27.1614 5.64709 27.3717 5.85745 27.3717 6.11768C27.3717 6.37792 27.1609 6.58827 26.9011 6.58827Z"
  //     fill={color ? color : '#DC0032'}
  //   />
  // </svg>
);
