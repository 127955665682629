import {
  breakpoints,
  colors,
  elements,
  misc,
  spacing,
  typography,
} from '@hp/theme';
import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { BorderPanelCss } from './BorderPanel';

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  CHAT = 'chat',
  TEXT = 'text',
  BANNER_PRIMARY = 'banner-primary',
  BANNER_SECONDARY = 'banner-secondary',
  MENU_PRIMARY = 'menu-primary',
  MENU_SECONDARY = 'menu-secondary',
  MENU_TEXT = 'menu-text',
  GROUP = 'group',
}

export type ButtonProps = {
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  buttonType: ButtonType;
  fullWidth?: boolean;
  bottom?: boolean;
  icon?: ReactNode;
  animate?: boolean;
  height?: string;
  canWrap?: boolean;
  ['data-test']?: string;
  fakeDisabled?: boolean;
} & Omit<React.HTMLProps<HTMLButtonElement>, 'as' | 'ref' | 'label'>;

const StyledButton = styled.button<ButtonProps>`
  position: relative;
  display: flex;
  align-items: ${({ canWrap }) => (canWrap ? 'flex-start' : 'center')};
  justify-content: center;
  position: relative;
  height: ${({ height = elements.buttonHeight }) => height};
  width: 100%;
  color: ${colors.white};
  background: linear-gradient(54.24deg, #b80951 6.62%, #ed1944 51.31%);
  cursor: pointer;
  font-size: ${typography.fontSize.body};
  line-height: ${typography.lineHeight.body};
  font-weight: ${typography.fontWeight.normal};
  box-shadow: ${misc.shadow_button};
  padding: 16px;
  border: none;
  border-radius: 5px;
  outline: none;

  @media (min-width: ${breakpoints.minDesktop}) {
    width: ${({ fullWidth }) => (fullWidth ? '100%' : elements.buttonMaxWidth)};
    max-width: 100%;
    white-space: ${({ canWrap }) => (canWrap ? 'wrap' : 'nowrap')};
  }

  :hover {
    background: ${colors.red_dark};

    ${({ animate }) =>
      animate &&
      css`
        > .buttonIcon {
          @keyframes animationFrames {
            0% {
              transform: translate(0px, 0px);
            }
            100% {
              transform: translate(10px, 0px);
            }
          }
          animation: animationFrames 0.8s ease-out 0s alternate infinite none
            running;
        }
      `};
  }

  :disabled {
    cursor: not-allowed;
    color: ${colors.black_opacity_05};
    background: ${colors.disabled};
    border: none;
    box-shadow: none;
  }

  ${({ fakeDisabled }) =>
    fakeDisabled &&
    css`
      color: ${colors.black_opacity_05};
      background: ${colors.disabled};
      border: none;
      box-shadow: none;

      :hover {
        background: ${colors.disabled};
      }
    `};

  ${({ buttonType }) =>
    buttonType === ButtonType.MENU_PRIMARY &&
    css`
      @media (min-width: ${breakpoints.minDesktop}) {
        height: ${elements.buttonHeightMenu};
        width: auto;
      }
    `};

  ${({ buttonType, height = elements.buttonHeightSmall }) =>
    buttonType === ButtonType.SECONDARY &&
    css`
      height: ${height};
      color: ${colors.red_dark};
      background: ${colors.transparent};
      border: 2px solid ${colors.red_dark};
      box-shadow: none;

      :hover {
        background: ${colors.red_dark_opacity_01};
      }

      :disabled {
        border: 2px solid ${colors.gray_mid};
      }
    `};

  ${({ buttonType }) =>
    buttonType === ButtonType.MENU_SECONDARY &&
    css`
      @media (min-width: ${breakpoints.minDesktop}) {
        height: ${elements.buttonHeightMenu};
        width: auto;
      }
      color: ${colors.red_dark};
      background: ${colors.transparent};
      border: 2px solid ${colors.red_dark};

      box-shadow: none;

      :hover {
        background: ${colors.red_dark_opacity_01};
      }

      :disabled {
        border: 2px solid ${colors.gray_mid};
      }
    `};

  ${({ buttonType }) =>
    buttonType === ButtonType.CHAT &&
    css`
      position: fixed;
      bottom: 0;
      right: 0;
      width: ${elements.buttonChatMinWidth};
      height: ${elements.buttonHeightBottom};
      color: ${colors.white};
      background: ${colors.red_dark};
      padding-bottom: 24px;
      box-shadow: none;
      border-radius: 0;
      border-top-left-radius: 35px;
      z-index: 9;

      :hover {
        background: linear-gradient(
            0deg,
            rgba(65, 64, 66, 0.4),
            rgba(65, 64, 66, 0.4)
          ),
          #a90034;
      }
    `};
  ${({ buttonType }) =>
    buttonType === ButtonType.MENU_TEXT &&
    css`
      width: auto;
      min-width: 0;
      height: auto;
      font-size: ${typography.fontSize.body};
      line-height: ${typography.lineHeight.bodySmall};
      font-weight: ${typography.fontWeight.light};
      color: ${colors.red_dark};
      background: transparent;
      padding: 0;
      box-shadow: none;
      text-align: center;
      margin: 0 auto;

      :hover {
        background: transparent;
        text-decoration: underline;
      }

      @media (min-width: ${breakpoints.minDesktop}) {
        width: auto;
      }
    `};

  ${({ buttonType }) =>
    buttonType === ButtonType.TEXT &&
    css`
      width: auto;
      min-width: 0;
      height: auto;
      font-size: ${typography.fontSize.bodySmall};
      line-height: ${typography.lineHeight.bodySmall};
      color: ${colors.red_dark};
      background: transparent;
      padding: 0;
      box-shadow: none;

      :hover {
        background: transparent;
        text-decoration: underline;
      }

      @media (min-width: ${breakpoints.minDesktop}) {
        width: auto;
      }
    `};

  ${({ buttonType }) =>
    buttonType === ButtonType.BANNER_PRIMARY &&
    css`
      justify-content: space-between;
      height: 54px;
      width: 100%;
      min-width: 0;
      font-size: ${typography.fontSize.bodyMicro};
      color: ${colors.white};
      background: ${colors.red_main};
      padding: ${spacing.s} ${spacing.m};
      border: none;
      border-radius: 0;
      box-shadow: none;

      :hover {
        background: ${colors.red_main};
      }

      @media (min-width: ${breakpoints.minDesktop}) {
        font-size: ${typography.fontSize.body};
        height: 68px;
        width: 100%;
        padding-left: ${spacing.l};
      }
    `};

  ${({ buttonType }) =>
    buttonType === ButtonType.BANNER_SECONDARY &&
    css`
      justify-content: flex-start;
      height: 32px;
      width: 180px;
      font-size: 11px;
      line-height: 1px;
      color: ${colors.green_banner};
      background: ${colors.white};
      padding: ${spacing.s} ${spacing.s};
      border: none;
      border-radius: 0;
      box-shadow: none;
      margin-top: 10px;

      :hover {
        background: white;
      }

      @media (min-width: ${breakpoints.minDesktop}) {
        justify-content: flex-start;
        flex-shrink: 0;
        height: 42px;
        width: 204px;
        font-size: 12px;
        padding: ${spacing.s} ${spacing.m};
        margin: 0;
      }
    `};

  ${({ buttonType, height = '160px' }) =>
    buttonType === ButtonType.GROUP &&
    css`
      ${BorderPanelCss};
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      height: ${height};
      background: ${colors.white};
      border: 1px solid ${colors.note};
      border-radius: 5px;
      box-shadow: ${misc.shadow_button_group};
      margin: ${spacing.s} 0px;
      transition: height 0.3s;
      box-shadow: ${misc.shadow_button};
      overflow: hidden;

      :hover {
        background: ${colors.gray_superlight};
      }

      @media (min-width: 1px) {
        /* to override base button props */
        width: calc(50% - ${spacing.s});
        padding: 8px 0 0 0;
      }
    `};

  ${({ bottom }) =>
    bottom &&
    css`
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: ${elements.buttonHeightBottom};
      padding-bottom: 24px;
      z-index: 9;

      @media (max-width: ${breakpoints.maxMobile}) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      @media (min-width: ${breakpoints.minDesktop}) {
        position: relative;
        height: ${elements.buttonHeight};
        padding-bottom: 16px;
      }

      @media (max-height: ${breakpoints.unfixSubmitButton}) {
        position: relative !important;
      }
    `};
`;

const IconWrapper = styled.span<ButtonProps>`
  flex-shrink: 0;
  position: absolute;
  right: 40px;
  padding-left: 25px;
  line-height: 0;

  ${({ buttonType }) =>
    buttonType === ButtonType.CHAT &&
    css`
      position: relative;
      right: 0;
      padding-left: 12px;
    `};

  ${({ buttonType }) =>
    buttonType === ButtonType.TEXT &&
    css`
      position: relative;
      right: 0;
      padding-left: 0;
      padding-right: ${spacing.s};
    `};

  ${({ buttonType }) =>
    buttonType === ButtonType.BANNER_PRIMARY &&
    css`
      position: absolute;
      right: 0;
      padding-left: 0;
      padding-right: ${spacing.m};
    `};

  ${({ buttonType }) =>
    buttonType === ButtonType.BANNER_SECONDARY &&
    css`
      position: absolute;
      right: 0;
      padding-left: 0;
      padding-right: ${spacing.s};
    `};
`;

export const Button: FC<ButtonProps> = ({
  className,
  type,
  buttonType,
  children,
  icon,
  ...props
}) => (
  <StyledButton
    className={className}
    type={type}
    buttonType={buttonType}
    {...props}
  >
    {!!icon && buttonType === ButtonType.TEXT && (
      <IconWrapper buttonType={buttonType} className="buttonIcon">
        {icon}
      </IconWrapper>
    )}
    {children}
    {!!icon && buttonType !== ButtonType.TEXT && (
      <IconWrapper buttonType={buttonType} className="buttonIcon">
        {icon}
      </IconWrapper>
    )}
  </StyledButton>
);
