import { colors } from '@hp/theme';
import React, { FC } from 'react';

type ColorSet = {
  color?: string;
  backgroundColor?: string;
  shadowColor?: string;
  colorShadow?: string;
};

type Props = {
  className?: string;
  zoom?: number;
  strokeWidth?: number | string;
} & ColorSet;

type SpecialProps = {
  className?: string;
  active?: boolean;
  zoom: number;
};

const redColors: ColorSet = {
  color: colors.red_main,
  colorShadow: '#FF4B68',
};

const grayColors: ColorSet = {
  color: colors.black,
  colorShadow: colors.gray_mid,
};

export const BoxIconRaw: FC<Props> = ({
  className,
  color = colors.red_main,
  backgroundColor = colors.white,
  shadowColor = colors.gray_light,
  colorShadow = grayColors.colorShadow,
  zoom = 1,
}) => {
  const strokeWidth = 2 / zoom;

  return (
    <svg
      width={43 * zoom}
      height={38 * zoom}
      viewBox="0 0 43 38"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.69702 9.42554L21.5492 18.617V37L4.69702 27.8085V9.42554Z"
        fill={backgroundColor}
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.4014 9.42553L21.5492 1V18.625L38.4014 9.42553Z"
        fill={shadowColor}
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5493 18.617L38.4015 9.42554V27.0426L21.5493 37V18.617Z"
        fill={shadowColor}
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        style={{ transition: 'fill .1s ease' }}
        d="M4.69697 9.42553L21.5492 1V18.4791L4.69697 9.42553Z"
        fill={colorShadow}
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.24998 16.2272L4.69702 9.42554V14.32L1.24998 16.2272Z"
        fill={backgroundColor}
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6132 26.9047L21.5492 18.617L38.4014 9.42554L41.0825 16.9472L24.6132 26.9047Z"
        fill={backgroundColor}
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const BoxIcon: React.FC<SpecialProps> = ({ active, ...props }) => {
  return <BoxIconRaw {...(active ? redColors : grayColors)} {...props} />;
};
