import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const NotFoundIllustration: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="163"
    height="68"
    viewBox="0 0 163 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M142 47V34.9182L148.557 28.4283C149.35 27.643 149.347 26.3602 148.55 25.5786L142.846 19.9862C142.296 19.4467 141.408 19.4696 140.886 20.0367V20.0367C140.391 20.5751 140.408 21.4083 140.926 21.9255L145.279 26.2789C145.675 26.6748 145.669 27.3187 145.265 27.7068L141.84 31H132.22C131.824 30.9999 131.443 31.154 131.157 31.4302L126.317 36.0969C126.034 36.3692 125.865 36.7399 125.842 37.1337C125.82 37.5275 125.947 37.9152 126.197 38.2182L129.277 42.6307C129.538 42.9476 129.914 43.1467 130.32 43.1841C130.727 43.2215 131.132 43.0941 131.446 42.83C131.76 42.5659 131.957 42.1867 131.994 41.7758C132.031 41.365 131.905 40.9561 131.643 40.6391L129.485 37.3436L132 34.9182V47"
      stroke="#414042"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M136 49.8284L136 63.5376C136 64.615 135.129 65.4895 134.051 65.4932V65.4932C132.973 65.497 132.095 64.6267 132.089 63.5481L132 47"
      stroke="#414042"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M138 49.8284L138 63.5376C138 64.615 138.871 65.4895 139.949 65.4932V65.4932C141.027 65.497 141.905 64.6267 141.911 63.5481L142 47"
      stroke="#414042"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M137.5 29C139.433 29 141 27.433 141 25.5C141 23.567 139.433 22 137.5 22C135.567 22 134 23.567 134 25.5C134 27.433 135.567 29 137.5 29Z"
      stroke="#414042"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M94 57L41 57"
      stroke="#414042"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77 9H88.8571L106.143 32.12L118 40.5714V56.9981H114"
      stroke="#414042"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.7778 57H8V9H77"
      stroke="#414042"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M105.5 66C110.194 66 114 62.1944 114 57.5C114 52.8056 110.194 49 105.5 49C100.806 49 97 52.8056 97 57.5C97 62.1944 100.806 66 105.5 66Z"
      fill="#E6E7E8"
      stroke="#414042"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.5 66C37.1944 66 41 62.1944 41 57.5C41 52.8056 37.1944 49 32.5 49C27.8056 49 24 52.8056 24 57.5C24 62.1944 27.8056 66 32.5 66Z"
      fill="#E6E7E8"
      stroke="#414042"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79 15L83 30L100 32"
      stroke="#414042"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M109 30.428L116.66 24.0001"
      stroke="#414042"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.2087 30.9748V29.7252H38.3128V23.2331H36.4313L31.3755 29.9407V30.9748H36.8909V33H38.3128V30.9748H40.2087ZM33.099 29.7252L36.8909 24.77V29.7252H33.099ZM41.3019 28.1309C41.3019 31.1041 42.6377 33.1436 45.4959 33.1436C48.3829 33.1436 49.7043 31.1041 49.7043 28.1309C49.7043 25.1291 48.3829 23.0895 45.4959 23.0895C42.6377 23.0895 41.3019 25.1291 41.3019 28.1309ZM42.7526 28.1309C42.7526 26.077 43.4564 24.4684 45.4959 24.4684C47.5642 24.4684 48.2536 26.077 48.2536 28.1309C48.2536 30.1561 47.5642 31.7648 45.4959 31.7648C43.4564 31.7648 42.7526 30.1561 42.7526 28.1309ZM59.5511 30.9748V29.7252H57.6552V23.2331H55.7737L50.7179 29.9407V30.9748H56.2333V33H57.6552V30.9748H59.5511ZM52.4414 29.7252L56.2333 24.77V29.7252H52.4414Z"
      fill="#DC0032"
    />
    <path
      d="M8 49H24"
      stroke="#414042"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41 49H98"
      stroke="#414042"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 67H161.5"
      stroke="#414042"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M132 49H142"
      stroke="#414042"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="117.5" cy="31.5" r="1.5" fill="#414042" />
    <circle cx="122.5" cy="25.5" r="2.5" fill="#414042" />
    <circle cx="129.5" cy="12.5" r="3.5" fill="#414042" />
    <circle cx="125.5" cy="11.5" r="4.5" fill="#414042" />
    <circle cx="124.5" cy="24.5" r="1.5" fill="#414042" />
    <circle cx="120.5" cy="11.5" r="1.5" fill="#414042" />
    <circle cx="133.5" cy="2.5" r="2.5" fill="#414042" />
  </svg>
);
