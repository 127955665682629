import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const NoOrdersIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 68 68"
  >
    <g>
      <g>
        <g id="Delivered_8_">
          <rect id="XMLID_8230_" fill="none" width="68" height="68" />
          <path
            fill="#414042"
            d="M54.001,54.003h-40V13.997h40V54.003z M16.001,52.003h36V15.997h-36V52.003z"
          />
        </g>
        <path
          fill="#414042"
          d="M40,30H28V19c0-0.553,0.447-1,1-1s1,0.447,1,1v9h8v-9c0-0.553,0.447-1,1-1s1,0.447,1,1V30z"
        />
      </g>
      <path
        id="XMLID_8228_"
        fill="#DC0032"
        d="M40,38.341l-3.402,2.04c-0.038,0.022-0.086,0.034-0.136,0.034h0
		c-0.055,0-0.107-0.014-0.147-0.039l-0.105-0.065l-0.346-0.214c-0.077-0.047-0.137-0.155-0.137-0.245v-0.358v-0.178
		c0-0.091,0.062-0.219,0.141-0.264l0.069-0.042l3.479-2.086l-5.166-2.863c-0.121-0.067-0.319-0.067-0.439-0.001l-5.328,2.893
		l6.178,3.536c0.08,0.044,0.141,0.148,0.141,0.242v0.024l0,0.338l0,0.782l-0.002,3.957c0,0.092-0.063,0.198-0.144,0.241
		l-0.466,0.258c-0.002,0.001-0.005,0.001-0.007,0.002c-0.038,0.019-0.083,0.03-0.132,0.03H34.04
		c-0.056,0.001-0.108-0.01-0.148-0.031l-0.293-0.162l-0.174-0.096c-0.079-0.042-0.142-0.149-0.142-0.241l-0.001-3.957H33.28l0-0.339
		c-0.004-0.048-0.039-0.106-0.078-0.128l-5.201-3.008l-0.002,5.882c0,0.138,0.097,0.308,0.216,0.378l5.605,3.284
		c0.119,0.07,0.314,0.069,0.432-0.001l5.531-3.287C39.903,44.581,40,44.411,40,44.273L40,38.341z"
      />
    </g>
  </svg>
);
