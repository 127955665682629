import React, { FC, SVGAttributes } from 'react';

type Props = {
  className?: string;
  width?: SVGAttributes<SVGElement>['width'];
};

export const CourierColorsIcon: FC<Props> = ({ className, width = 20 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      r="2.1875"
      transform="matrix(-1 0 0 1 5.3125 14.6875)"
      stroke="#414042"
      strokeLinecap="round"
    />
    <circle
      r="2.1875"
      transform="matrix(-1 0 0 1 14.6875 14.6875)"
      stroke="#414042"
      strokeLinecap="round"
    />
    <path d="M7.5 15L12.5 15" stroke="#414042" strokeLinecap="round" />
    <path
      d="M16.875 15H19.375V10L16.25 6.875H14.375V12.1875"
      stroke="#414042"
      strokeLinecap="round"
    />
    <path
      d="M14.375 6.5625V3.125H0.625L0.625 15H3.125"
      stroke="#414042"
      strokeLinecap="round"
    />
    <path d="M3.75 8.125L11.25 8.125" stroke="#DC0032" strokeLinecap="round" />
    <path
      d="M9.375 6.25L11.25 8.125L9.375 10"
      stroke="#DC0032"
      strokeLinecap="round"
    />
  </svg>
);
