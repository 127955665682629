import { useOtherDataLocalStorage } from '@hp/order/src/hooks/useOtherDataLocalStorage';

import { RoutesType } from '../types';

/** catalog of all visited pages - not executive!, only a collection for manipulation with managed history */
export const useHistory = (skip?: boolean) => {
  const [other, , utils] = useOtherDataLocalStorage(
    skip ? { skip: true } : { lazy: true },
  );

  const push = (route: keyof RoutesType) => {
    utils.setProperty('history', (prevHistory) => {
      const last = prevHistory ? prevHistory[prevHistory.length - 1] : null;
      const newHistory =
        prevHistory && last !== route ? [...prevHistory, route] : [route];
      if (newHistory.length > 5) newHistory.shift();
      return newHistory;
    });
  };

  return {
    push,
    current: other?.history?.[other?.history?.length - 1] ?? null,
    previous: other?.history?.[other?.history?.length - 2] ?? null,
    history: other?.history ?? [],
  };
};
