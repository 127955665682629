import { colors } from '@hp/theme';
import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

export const MapMarkerIcon: FC<Props> = ({
  className,
  color = colors.white,
}) => (
  <svg
    className={className}
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 8.81484C17 15.1711 9 20.6194 9 20.6194C9 20.6194 1 15.1711 1 8.81484C1 6.64739 1.84285 4.56871 3.34315 3.03609C4.84344 1.50347 6.87827 0.642456 9 0.642456C11.1217 0.642456 13.1566 1.50347 14.6569 3.03609C16.1571 4.56871 17 6.64739 17 8.81484Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 6L9 7.84615V11"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1765 7.15L13 5.8M13 5.8L9.23529 4L5 5.8V10.75L9.23529 13L13 11.2V5.8Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
