import { breakpoints, colors, elements } from '@hp/theme';
import React from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  bodyWidth?: string;
  bgColor?: string | 'gradient';
};

const Container = styled.div<Pick<Props, 'bgColor'>>`
  display: flex;
  flex: auto;
  flex-direction: column;
  width: 100%;
  background: ${({ bgColor }) =>
    bgColor === 'gradient'
      ? 'linear-gradient(180deg, rgba(245,245,245,1) 69%, rgba(255,255,255,1) 100%);'
      : bgColor};
`;

const Body = styled.div<Required<Pick<Props, 'bodyWidth'>>>`
  display: flex;
  justify-content: start;
  flex-direction: column;
  flex: 1 0 auto; // align footer to bottom
  width: 100%;
  padding: 0;

  @media (min-width: ${breakpoints.minDesktop}) {
    max-width: 100%;
    width: ${({ bodyWidth }) => bodyWidth};
    margin: 0 auto;
  }
`;

/** base layout container (without colors, shadows.., just layout)*/
export const HorizontalLayout: React.FC<Props> = ({
  className,
  children,
  bodyWidth = elements.desktopContainerWidth,
  bgColor = colors.gray_superlight,
}) => {
  return (
    <Container className={className} bgColor={bgColor}>
      <Body bodyWidth={bodyWidth}>{children}</Body>
    </Container>
  );
};
