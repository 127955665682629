import { colors } from '@hp/theme';
import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

export const AvatarIcon: FC<Props> = ({ className, color = colors.white }) => (
  <svg
    className={className}
    width="22"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M15.6666 20.25V18.4167C15.6666 17.4442 15.2803 16.5116 14.5927 15.8239C13.9051 15.1363 12.9724 14.75 12 14.75H5.58329C4.61083 14.75 3.6782 15.1363 2.99057 15.8239C2.30293 16.5116 1.91663 17.4442 1.91663 18.4167V20.25"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.79167 11.0833C10.8167 11.0833 12.4583 9.44171 12.4583 7.41667C12.4583 5.39162 10.8167 3.75 8.79167 3.75C6.76662 3.75 5.125 5.39162 5.125 7.41667C5.125 9.44171 6.76662 11.0833 8.79167 11.0833Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="22" height="22" fill="white" transform="translate(1 1)" />
      </clipPath>
    </defs>
  </svg>
);
