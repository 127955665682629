import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const SquaresColorIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2.25"
      y="2.25"
      width="6"
      height="6"
      stroke="#DC0032"
      strokeLinecap="round"
    />
    <rect
      x="3.75"
      y="12.75"
      width="6"
      height="6"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="12.75"
      y="3.75"
      width="6"
      height="6"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="12.75"
      y="12.75"
      width="6"
      height="6"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="3.75"
      y="21.75"
      width="6"
      height="6"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="12.75"
      y="21.75"
      width="6"
      height="6"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="21.75"
      y="3.75"
      width="6"
      height="6"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="21.75"
      y="12.75"
      width="6"
      height="6"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="21.75"
      y="21.75"
      width="6"
      height="6"
      stroke="#414042"
      strokeLinecap="round"
    />
  </svg>
);
