import { colors } from '@hp/theme';
import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

export const CalculatorIcon: FC<Props> = ({
  className,
  color = colors.red_dark,
}) => (
  <svg
    className={className}
    width="12"
    height="18"
    viewBox="0 0 12 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="11" height="17" stroke={color} />
    <rect x="2.5" y="2.5" width="7" height="3" stroke={color} />
    <rect x="2.5" y="8.5" width="1" height="1" stroke={color} />
    <rect x="2.5" y="11.5" width="1" height="1" stroke={color} />
    <rect x="2.5" y="14.5" width="1" height="1" stroke={color} />
    <rect x="5.5" y="8.5" width="1" height="1" stroke={color} />
    <rect x="5.5" y="11.5" width="1" height="1" stroke={color} />
    <rect x="5.5" y="14.5" width="1" height="1" stroke={color} />
    <rect x="8.5" y="8.5" width="1" height="1" stroke={color} />
    <rect x="8.5" y="11.5" width="1" height="1" stroke={color} />
    <rect x="8.5" y="14.5" width="1" height="1" stroke={color} />
  </svg>
);
