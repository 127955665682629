import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

export const CourierIcon: FC<Props> = ({ className, color }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9976 11.833L13.8631 13.113C13.8395 13.1266 13.8089 13.1342 13.7774 13.1342C13.7431 13.1342 13.7101 13.1257 13.6856 13.1102L13.6193 13.0692L13.4019 12.9346C13.3539 12.905 13.3158 12.8372 13.3158 12.7807V12.5563V12.4443C13.3158 12.3873 13.3548 12.3069 13.4042 12.2782L13.448 12.2518L15.6306 10.9431L12.3892 9.14639C12.3134 9.10451 12.1892 9.10404 12.1134 9.14592L8.77082 10.961L12.6471 13.1793C12.6974 13.2066 12.7355 13.272 12.7355 13.3309V13.3455V13.5572V14.048L12.7341 16.5309C12.7341 16.5887 12.6946 16.6551 12.6438 16.6824L12.3515 16.8443C12.3515 16.8443 12.3482 16.8447 12.3468 16.8452C12.3233 16.857 12.2946 16.864 12.264 16.864H12.2588C12.224 16.865 12.1911 16.8579 12.1656 16.8443L11.9816 16.7422L11.8729 16.6819C11.8231 16.6556 11.784 16.5887 11.784 16.5304L11.7835 14.0476H11.7826V13.8349C11.7798 13.8047 11.7581 13.768 11.7336 13.7544L8.47059 11.8669L8.46918 15.5572C8.46918 15.6438 8.53035 15.7506 8.60518 15.7944L12.1219 17.8546C12.1967 17.8984 12.3186 17.8979 12.3934 17.8537L15.8635 15.7916C15.9379 15.7473 15.9986 15.6405 15.9986 15.5535L15.9976 11.833Z"
      fill={color ? color : '#DC0032'}
    />
    <path
      d="M27.767 17.4121C27.7463 17.4121 27.7256 17.4107 27.7044 17.4079L24.7821 17.0183C22.1011 16.6611 20.0009 14.6281 19.5567 11.9604L18.8305 7.60602C18.7877 7.34955 18.9609 7.10719 19.2174 7.06437C19.4748 7.02202 19.7167 7.19472 19.7585 7.45119L20.4847 11.8055C20.8607 14.063 22.6376 15.783 24.9063 16.0855L27.8287 16.4752C28.0865 16.5095 28.2677 16.7463 28.2329 17.0037C28.2014 17.2404 27.9995 17.4121 27.767 17.4121Z"
      fill={color ? color : '#414042'}
    />
    <path
      d="M7.52843 24.9421C5.71196 24.9421 4.23431 23.464 4.23431 21.648C4.23431 19.832 5.71196 18.3539 7.52843 18.3539C9.3449 18.3539 10.8225 19.832 10.8225 21.648C10.8225 23.464 9.3449 24.9421 7.52843 24.9421ZM7.52843 19.2951C6.23102 19.2951 5.17549 20.3506 5.17549 21.648C5.17549 22.9454 6.23102 24.0009 7.52843 24.0009C8.82584 24.0009 9.88137 22.9454 9.88137 21.648C9.88137 20.3506 8.82584 19.2951 7.52843 19.2951Z"
      fill={color ? color : '#414042'}
    />
    <path
      d="M24.0014 24.9421C22.1854 24.9421 20.7073 23.464 20.7073 21.648C20.7073 19.832 22.1854 18.3539 24.0014 18.3539C25.8174 18.3539 27.2955 19.832 27.2955 21.648C27.2955 23.464 25.8174 24.9421 24.0014 24.9421ZM24.0014 19.2951C22.704 19.2951 21.6485 20.3506 21.6485 21.648C21.6485 22.9454 22.704 24.0009 24.0014 24.0009C25.2988 24.0009 26.3543 22.9454 26.3543 21.648C26.3543 20.3506 25.2988 19.2951 24.0014 19.2951Z"
      fill={color ? color : '#414042'}
    />
    <path
      d="M21.1774 22.1187H10.3539C10.0941 22.1187 9.8833 21.9083 9.8833 21.6481C9.8833 21.3878 10.0941 21.1775 10.3539 21.1775H21.1774C21.4377 21.1775 21.648 21.3878 21.648 21.6481C21.648 21.9083 21.4377 22.1187 21.1774 22.1187Z"
      fill={color ? color : '#414042'}
    />
    <path
      d="M29.649 21.1774C29.3887 21.1774 29.1784 20.967 29.1784 20.7068V16.4847C29.1784 16.2762 29.1083 16.071 28.9803 15.9073L23.5365 8.90727C23.0875 8.32986 22.4099 7.99904 21.6791 7.99904H3.76285C3.24379 7.99904 2.82167 8.42116 2.82167 8.94021V20.7068C2.82167 20.967 2.61085 21.1774 2.35108 21.1774C2.09132 21.1774 1.88049 20.967 1.88049 20.7068V8.94021C1.88049 7.9021 2.72473 7.05786 3.76285 7.05786H21.6791C22.7031 7.05786 23.6504 7.52139 24.2791 8.32986L29.7228 15.3299C29.9784 15.6579 30.1195 16.0682 30.1195 16.4856V20.7077C30.1195 20.967 29.9092 21.1774 29.649 21.1774Z"
      fill={color ? color : '#414042'}
    />
  </svg>
);
