import { config } from '@hp/config';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import GoogleMapReact from 'google-map-react';
import React, { FC } from 'react';

import { mapStyle } from './helper';
import { Marker } from './Marker';
import { Wrapper } from './styled';

type GoogleMapProps = {
  className?: string;
  height?: number | '100%';
  desktopHeight?: number | '100%';
  position?: {
    lat: number;
    lng: number;
  };
  zoom?: number;
};

export const GoogleMap: FC<GoogleMapProps> = ({
  className,
  height = 360,
  desktopHeight,
  position,
}) => {
  const { language } = useLanguage();

  const defPosition = { lat: 49.8401553, lng: 15.4716078 };

  const isDefPosition =
    (position?.lat === 0 || position?.lat === null) &&
    (position?.lng === 0 || position?.lng === null);

  return (
    <Wrapper
      className={className}
      height={height}
      desktopHeight={desktopHeight}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: config.app.googleApiKey,
          language: language,
          region: language,
          dummy: '', // only key is throwing error 🤦‍
        }}
        options={{
          styles: mapStyle,
        }}
        defaultCenter={isDefPosition ? defPosition : position}
        defaultZoom={isDefPosition ? 9 : 15}
      >
        {!isDefPosition && <Marker lat={position.lat} lng={position.lng} />}
      </GoogleMapReact>
    </Wrapper>
  );
};
