import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const HandColorsIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 15C1 15 4 13.276 6 13C7.5 12.7931 10 13.2761 11.8241 13.5713C13 13.7617 12.9432 15.3428 11.5 15.3428C10.3454 15.3428 9.17817 15.3428 8.57684 15.3428M3 19.0001C7 17.0001 11 17.5 12 17.5C13 17.5 15 16.5 18 14.9999C19.3947 14.3025 18.7361 12.7761 17 13.5C15.2639 14.2239 14 14.5 14 14.5"
      stroke="#414042"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="4.5"
      y="0.5"
      width="12"
      height="10"
      stroke="#414042"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 0.5H12.5V5.5L11.5 4.5L10.5 5.5L9.5 4.5L8.5 5.5V0.5Z"
      stroke="#DC0032"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
