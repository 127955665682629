import React, { FC } from 'react';

type Props = {
  className?: string;
  width?: string;
};

export const ParcelShopColorsIcon: FC<Props> = ({
  className,
  width = '20',
}) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.875 6.875H18.125V16.875H15.625V9.375H11.875V16.875H1.875V6.875Z"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="4.375"
      y="9.375"
      width="5"
      height="5"
      stroke="#DC0032"
      strokeLinecap="round"
    />
    <path
      d="M0.625 6.875L3.125 4.375L16.875 4.375L19.375 6.875"
      stroke="#414042"
      strokeLinecap="round"
    />
    <path
      d="M16.875 4.375V2.5H3.125V4.375"
      stroke="#414042"
      strokeLinecap="round"
    />
  </svg>
);
