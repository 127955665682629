import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const AccountSwitchIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.5 28.5H25.5" stroke="#DC0032" strokeLinecap="round" />
    <path
      d="M23.5 25.5L26.5 28.5L23.5 31.5"
      stroke="#DC0032"
      strokeLinecap="round"
    />
    <path
      d="M20.2983 9.45695C19.3117 9.06128 18.4938 8.33413 17.9853 7.40061C17.4768 6.46708 17.3096 5.38554 17.5124 4.34205C17.7151 3.29855 18.2752 2.35833 19.0963 1.68314C19.9173 1.00795 20.948 0.640003 22.011 0.642591C23.074 0.64518 24.1029 1.01814 24.9206 1.69732C25.7384 2.3765 26.2939 3.31944 26.4916 4.36391C26.6892 5.40838 26.5167 6.4891 26.0037 7.42013C25.4907 8.35117 24.6693 9.07432 23.6808 9.46519"
      stroke="#414042"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 19.5C14 17.3783 14.8429 15.3434 16.3431 13.8431C17.8434 12.3429 19.8783 11.5 22 11.5C24.1217 11.5 26.1566 12.3429 27.6569 13.8431C29.1571 15.3434 30 17.3783 30 19.5"
      stroke="#414042"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.79828 9.45695C7.81165 9.06128 6.99376 8.33413 6.4853 7.40061C5.97684 6.46708 5.8096 5.38554 6.01236 4.34205C6.21512 3.29855 6.7752 2.35833 7.59625 1.68314C8.4173 1.00795 9.44798 0.640003 10.511 0.642591C11.574 0.64518 12.6029 1.01814 13.4206 1.69732C14.2384 2.3765 14.7939 3.31944 14.9916 4.36391C15.1892 5.40838 15.0167 6.4891 14.5037 7.42013C13.9907 8.35117 13.1693 9.07432 12.1808 9.46519"
      stroke="#414042"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 19.5C2.5 17.9177 2.96919 16.371 3.84824 15.0554C4.7273 13.7398 5.97672 12.7145 7.43853 12.109C8.90034 11.5035 10.5089 11.345 12.0607 11.6537C13.6126 11.9624 15.038 12.7243 16.1569 13.8431"
      stroke="#414042"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
