import { colors } from '@hp/theme';
import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

export const UnitCmIcon: FC<Props> = ({
  className,
  color = colors.gray_mid,
}) => (
  <svg
    width="19"
    height="8"
    viewBox="0 0 19 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.812031 3.856C0.812031 5.74 2.18003 7.168 4.17203 7.168C5.13203 7.168 5.93603 6.832 6.53603 6.304L5.91203 5.38C5.51603 5.74 4.96403 6.016 4.22003 6.016C2.93603 6.016 2.08403 5.104 2.08403 3.856C2.08403 2.608 2.93603 1.708 4.22003 1.708C4.96403 1.708 5.51603 1.984 5.91203 2.344L6.53603 1.42C5.93603 0.892 5.13203 0.556 4.17203 0.556C2.18003 0.556 0.812031 1.972 0.812031 3.856ZM7.80625 0.724V7H9.05425V3.076C9.27025 2.392 9.83425 1.768 10.8423 1.768C11.9343 1.768 12.3783 2.5 12.3783 3.568V7H13.6263V3.064C13.8423 2.38 14.3943 1.768 15.3783 1.768C16.4823 1.768 16.9263 2.512 16.9263 3.58V7H18.1743V3.388C18.1743 1.828 17.4903 0.616 15.7863 0.616C14.6343 0.616 13.8303 1.204 13.4103 1.876C13.0623 1.084 12.4263 0.603999 11.2383 0.603999C10.2303 0.603999 9.51025 1.036 9.05425 1.672V0.724H7.80625Z"
      fill={color}
    />
  </svg>
);
