import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const PackagingIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2.5"
      y="2.5"
      width="27"
      height="27"
      stroke="#DC0032"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 5.5V12.5H19.5V5.5"
      stroke="#414042"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
