import { colors } from '@hp/theme';
import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

export const SendPackageIcon: FC<Props> = ({
  className,
  color = colors.white,
}) => (
  <svg
    className={className}
    width="34"
    height="32"
    viewBox="0 0 34 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.1371 12.843L16.379 6.63916"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.5162 20.7323V11.1696C32.5157 10.7504 32.4051 10.3386 32.1953 9.97565C31.9855 9.61269 31.6839 9.31128 31.3208 9.10166L22.9534 4.32029C22.59 4.11046 22.1777 4 21.7581 4C21.3384 4 20.9262 4.11046 20.5627 4.32029L12.1953 9.10166C11.8323 9.31128 11.5307 9.61269 11.3209 9.97565C11.1111 10.3386 11.0004 10.7504 11 11.1696V20.7323C11.0004 21.1516 11.1111 21.5633 11.3209 21.9263C11.5307 22.2893 11.8323 22.5907 12.1953 22.8003L20.5627 27.5817C20.9262 27.7915 21.3384 27.902 21.7581 27.902C22.1777 27.902 22.59 27.7915 22.9534 27.5817L31.3208 22.8003C31.6839 22.5907 31.9855 22.2893 32.1953 21.9263C32.4051 21.5633 32.5157 21.1516 32.5162 20.7323Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3228 9.92639L21.7581 15.9629L32.1934 9.92639"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.7581 28V15.9509"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 12L4 12"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 20L4 20"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 16L1 16"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
