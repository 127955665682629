// import { routes } from '../routes';
// import { RoutesType } from '../types';

import { toArray } from '@hp/utils';

import { useRouter } from './useRouter';

export const useFlow = () => {
  const router = useRouter();

  /** goes to the next page in expected flow */
  const nextPage = async (routeName?: string) => {
    const routeProps = router.current;
    if (!routeProps?.nextPages) return;
    const nextPagesRaw =
      typeof routeProps.nextPages === 'function'
        ? routeProps.nextPages()
        : routeProps.nextPages;

    const nextPages = toArray(nextPagesRaw).filter((x) =>
      !routeName ? true : x === routeName,
    );
    if (nextPages.length === 0) return;
    await router.push(nextPages[0]);
  };
  /** goes back in the expected flow (not in real history) */
  const back = async () => {
    console.log('TODO: history required');
  };

  return {
    nextPage,
    back,
  };
};
