export enum QueryParams {
  token = 'token',
  redirectTo = 'redirectTo',
  body = 'body',
  id = 'id',
  code = 'code',
  campaign = 'campaign',
  mode = 'mode',
  /** used by payment gateway, when payment is not sucessful */
  error = 'error',
}

export enum QueryParamModeValue {
  thankYou = 'thankYou',
  resend = 'resend',
  view = 'view',
}
