import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const FillDataIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 6.5H1.5V24.5H11.5"
        stroke="#414042"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 24.5H26.5V16.5"
        stroke="#414042"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 28.5H21.5"
        stroke="#DC0032"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8284 15.9346L16.4142 17.3488"
        stroke="#414042"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.4351 2.5L31.2635 5.32843"
        stroke="#DC0032"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2427 11.6922L26.3137 4.62109L29.1421 7.44952L20.3033 16.2884L16.4142 17.349L17.4749 13.4599"
        stroke="#414042"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
