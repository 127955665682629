import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const CalendarClockIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 10.5C14 10.2239 13.7761 10 13.5 10C13.2239 10 13 10.2239 13 10.5H14ZM13.5 13.5H13V13.7071L13.1464 13.8536L13.5 13.5ZM14.6464 15.3536C14.8417 15.5488 15.1583 15.5488 15.3536 15.3536C15.5488 15.1583 15.5488 14.8417 15.3536 14.6464L14.6464 15.3536ZM13 10.5V13.5H14V10.5H13ZM13.1464 13.8536L14.6464 15.3536L15.3536 14.6464L13.8536 13.1464L13.1464 13.8536Z"
      fill="#DC0032"
    />
    <circle
      cx="13.5"
      cy="13.5"
      r="5"
      stroke="#DC0032"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0293 1.5V3.5"
      stroke="#414042"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.9707 1.5V3.5"
      stroke="#414042"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0294 3H14.5C15.0523 3 15.5 3.44772 15.5 4V7M11.5 3H3.97059M2.6 3H2.5C1.94772 3 1.5 3.44772 1.5 4V16C1.5 16.5523 1.94772 17 2.5 17H7.5"
      stroke="#414042"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.49997 6H15.5"
      stroke="#414042"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
