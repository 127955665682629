import { colors } from '@hp/theme';
import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

export const TimerIcon: FC<Props> = ({ className, color = colors.white }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1.5C11.9665 1.5 13.8722 2.18186 15.3923 3.42941C16.9125 4.67696 17.953 6.413 18.3367 8.34173C18.7203 10.2705 18.4233 12.2726 17.4963 14.0069C16.5693 15.7412 15.0696 17.1004 13.2528 17.853C11.436 18.6055 9.41442 18.7048 7.53258 18.134C5.65074 17.5631 4.02505 16.3574 2.93251 14.7223C1.83997 13.0872 1.34818 11.1239 1.54093 9.16686C1.73368 7.2098 2.59905 5.38013 3.98959 3.98959L6.9948 6.9948L10 10"
      stroke={color}
      strokeLinecap="round"
    />
    <path d="M10 15.5V16.5" stroke={color} strokeLinecap="round" />
    <path d="M10 3.5V4.5" stroke={color} strokeLinecap="round" />
    <path d="M4.5 10L3.5 10" stroke={color} strokeLinecap="round" />
    <path d="M16.5 10L15.5 10" stroke={color} strokeLinecap="round" />
  </svg>
);
