import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const QRCodeIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 1.5L1.5 1.5V4" stroke="#DC0032" strokeLinecap="round" />
    <path d="M16 1.5H18.5V4" stroke="#DC0032" strokeLinecap="round" />
    <path d="M1.5 16V18.5H4" stroke="#DC0032" strokeLinecap="round" />
    <path d="M18.5 16V18.5H16" stroke="#DC0032" strokeLinecap="round" />
    <rect
      x="1.5"
      y="9.5"
      width="3"
      height="1"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="9.5"
      y="17.5"
      width="1"
      height="1"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="9.5"
      y="11.5"
      width="1"
      height="1"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="9.5"
      y="3.5"
      width="1"
      height="5"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="13.5"
      y="11.5"
      width="1"
      height="3"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="17.5"
      y="10.5"
      width="1"
      height="2"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="7.5"
      y="15.5"
      width="3"
      height="1"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="1.5"
      y="12.5"
      width="2"
      height="1"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="13.5"
      y="9.5"
      width="1"
      height="1"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="11.5"
      y="9.5"
      width="1"
      height="1"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="14.5"
      y="4.5"
      width="1"
      height="1"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="12.5"
      y="1.5"
      width="1"
      height="1"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="6.5"
      y="1.5"
      width="1"
      height="1"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="4.5"
      y="4.5"
      width="1"
      height="1"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="1.5"
      y="6.5"
      width="1"
      height="1"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="5.5"
      y="6.5"
      width="1"
      height="1"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="13.5"
      y="6.5"
      width="1"
      height="1"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="4.5"
      y="14.5"
      width="1"
      height="1"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="6.5"
      y="12.5"
      width="1"
      height="1"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="15.5"
      y="11.5"
      width="1"
      height="1"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="14.5"
      y="14.5"
      width="1"
      height="1"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="12.5"
      y="17.5"
      width="1"
      height="1"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="17.5"
      y="6.5"
      width="1"
      height="1"
      stroke="#414042"
      strokeLinecap="round"
    />
    <rect
      x="7.5"
      y="9.5"
      width="3"
      height="1"
      stroke="#414042"
      strokeLinecap="round"
    />
  </svg>
);
