import { colors } from '@hp/theme';
import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

export const ArrowSmallIcon: FC<Props> = ({
  className,
  color = colors.gray_mid,
}) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <path
        d="M6 12L16 12"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 7L18 12L13 17"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
  </svg>
);
