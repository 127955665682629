import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const WeightColorIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 19.5H12H17L16.09 10.4005C16.0389 9.88929 15.6088 9.5 15.095 9.5H13V7.5H13.5C14.3284 7.5 15 6.82843 15 6C15 5.17157 14.3284 4.5 13.5 4.5H10.5C9.67157 4.5 9 5.17157 9 6C9 6.82843 9.67157 7.5 10.5 7.5H11V9.5H8.90499C8.39124 9.5 7.96107 9.8893 7.90995 10.4005L7 19.5Z"
        stroke="#808285"
        strokeLinejoin="round"
      />
      <path
        d="M11.7884 13.3296L10.3767 14.6473L11.8825 16.1061V16.2002H11.1767L9.7649 14.8355V16.2002H9.2002V12.2002H9.7649V14.5061L11.1296 13.2355H11.7884V13.3296Z"
        fill="#DC0032"
      />
      <path
        d="M13.3415 13.1885C13.765 13.1885 14.0944 13.3767 14.2826 13.5649V13.2355H14.8473V15.8708C14.8473 16.812 14.1885 17.2826 13.4356 17.2826C12.8709 17.2826 12.4944 17.0944 12.3062 17.0002L12.4473 16.4826C12.6356 16.6238 12.965 16.812 13.4356 16.812C13.8591 16.812 14.2826 16.5767 14.2826 16.012V15.6355C14.0473 15.8708 13.7179 16.0591 13.3415 16.0591C12.4944 16.0591 11.9297 15.4002 11.9297 14.6473C11.9297 13.8473 12.4944 13.1885 13.3415 13.1885ZM13.4826 15.4944C13.812 15.4944 14.0944 15.3532 14.2826 15.1649V14.0355C14.1415 13.8473 13.812 13.7061 13.4826 13.7061C12.8709 13.7061 12.4944 14.0826 12.4944 14.6002C12.5415 15.1179 12.9179 15.4944 13.4826 15.4944Z"
        fill="#DC0032"
      />
    </svg>
  );
};
