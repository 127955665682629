import { breakpoints, elements } from '@hp/theme';
import React from 'react';
import styled from 'styled-components';

import { ExtraPaddings } from './ExtraPaddings';
import { FullHeightLayout } from './FullHeightLayout';
import { HorizontalLayout } from './HorizontalLayout';
import { Inner } from './Inner';
import { Paper } from './Paper';
import { HideOnDesktop, HideOnMobile } from './responsive';

type PageLayoutProps = {
  noMargin?: boolean;
};

type DefaultPageLayoutProps = {
  breadCrumb?: JSX.Element;
  noMargin?: boolean;
  breadCrumbAlways?: boolean;
  wider?: boolean;
};

type WellKnownPageLayoutType = {
  default: React.FC<DefaultPageLayoutProps>;
  noPaper: React.FC<DefaultPageLayoutProps>;
};

const Wrapper = styled.div<PageLayoutProps>`
  margin-top: ${({ noMargin }) => !noMargin && elements.navigationHeightMobile};
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.minDesktop}) {
    margin-top: ${elements.navigationHeight};
  }
`;

/** page layout with extra space for menu on the top */
export const PageLayout: React.FC<PageLayoutProps> &
  WellKnownPageLayoutType = ({ children, noMargin }) => {
  return <Wrapper noMargin={noMargin}>{children}</Wrapper>;
};

PageLayout.default = ({
  children,
  breadCrumb,
  noMargin,
  breadCrumbAlways,
  wider,
  ...props
}) => {
  return (
    <PageLayout noMargin={noMargin}>
      <FullHeightLayout exceptDesktopFooter {...props}>
        <HorizontalLayout
          bodyWidth={
            wider
              ? elements.desktopContainerWidthWider
              : elements.desktopContainerWidth
          }
        >
          {breadCrumb && (
            <>
              {breadCrumbAlways && <HideOnDesktop>{breadCrumb}</HideOnDesktop>}

              <HideOnMobile>
                <Inner paddingY="m">{breadCrumb}</Inner>
              </HideOnMobile>
            </>
          )}
          <Paper noBreadcrumbMargin={!breadCrumb}>
            <ExtraPaddings vertical>{children}</ExtraPaddings>
          </Paper>
        </HorizontalLayout>
      </FullHeightLayout>
    </PageLayout>
  );
};

PageLayout.noPaper = ({ children, breadCrumb, noMargin, ...props }) => {
  return (
    <PageLayout noMargin={noMargin}>
      <FullHeightLayout exceptDesktopFooter {...props}>
        <HorizontalLayout>
          {breadCrumb && (
            <HideOnMobile>
              <Inner paddingY="m">{breadCrumb}</Inner>
            </HideOnMobile>
          )}
          <ExtraPaddings vertical>{children}</ExtraPaddings>
        </HorizontalLayout>
      </FullHeightLayout>
    </PageLayout>
  );
};
