import { UtmInput } from '@hp/core/shared';
import { deArray, useObjectLocalStorage } from '@hp/utils';
import { NextPageContext } from 'next';
import { useEffect } from 'react';

import { UtmQueryParams } from '../routes';

export type UtmParams = {
  utmData: UtmInput;
  createdAt: number;
};

export type UtmQuery = NextPageContext['query'] &
  Partial<Record<UtmQueryParams, NextPageContext['query']['any']>>;

export const useUtmSource = (query?: UtmQuery): [UtmParams, boolean] => {
  const [utmParams, setUtmParams, loaded] = useObjectLocalStorage<UtmParams>(
    'utm',
    null,
    {
      lazy: true,
    },
  );

  const { utm_source, utm_medium, utm_content, utm_campaign } = query || {};

  useEffect(() => {
    if (utm_source)
      setUtmParams({
        utmData: {
          campaign: deArray(utm_campaign),
          medium: deArray(utm_medium),
          source: deArray(utm_source),
          content: deArray(utm_content),
        },
        createdAt: new Date().getTime(),
      });
  }, [utm_source, utm_medium, utm_content, utm_campaign, setUtmParams]);

  return [utmParams, loaded];
};
