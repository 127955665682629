import { spacing } from '@hp/theme';
import styled, { css } from 'styled-components';

type RowMarginSize = Extract<
  keyof typeof spacing,
  'zero' | 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl'
>;

type RowProps = {
  nowrap?: boolean;
  marginSize?: RowMarginSize;
  marginTop?: RowMarginSize;
  marginBottom?: RowMarginSize;
  noBottomMargin?: boolean;
  verticallyCentered?: boolean;
};

const rowMarginCss = css<RowProps>`
  ${({ marginSize, marginTop }) =>
    marginSize || marginTop
      ? css`
          margin-top: ${spacing[marginTop || marginSize]};
        `
      : ''}
  ${({ marginSize, marginBottom }) =>
    marginSize || marginBottom
      ? css`
          margin-bottom: ${spacing[marginBottom || marginSize]};
        `
      : ''}
  margin-bottom: ${({ noBottomMargin }) => noBottomMargin && '0'};
`;

export const CenteredRow = styled.div<RowProps>`
  width: 100%;
  ${rowMarginCss};
  display: flex;
  justify-content: center;
  align-items: ${({ verticallyCentered }) => verticallyCentered && 'center'};
  flex-wrap: ${({ nowrap }) => (nowrap ? 'nowrap' : 'wrap')};
`;

export const Row = styled.div<RowProps>`
  width: 100%;
  ${rowMarginCss};
  display: flex;
  justify-content: flex-start;
  align-items: ${({ verticallyCentered }) => verticallyCentered && 'center'};
  flex-wrap: ${({ nowrap }) => (nowrap ? 'nowrap' : 'wrap')};
`;

export const AlignRightRow = styled.div<RowProps>`
  width: 100%;
  ${rowMarginCss};
  display: flex;
  justify-content: flex-end;
  align-items: ${({ verticallyCentered }) => verticallyCentered && 'center'};
  flex-wrap: ${({ nowrap }) => (nowrap ? 'nowrap' : 'wrap')};
`;

export const SpaceBetween = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SpaceBetweenRow = styled.div<RowProps>`
  width: 100%;
  ${rowMarginCss};
  display: flex;
  justify-content: space-between;
  align-items: ${({ verticallyCentered }) => verticallyCentered && 'center'};
  flex-wrap: ${({ nowrap }) => (nowrap ? 'nowrap' : 'wrap')};
`;
