import { breakpoints, colors, spacing } from '@hp/theme';
import styled from 'styled-components';

type PaperProps = {
  noBreadcrumbMargin?: boolean;
};

export const Paper = styled.div<PaperProps>`
  background: ${colors.white};
  position: relative;

  @media (min-width: ${breakpoints.minDesktop}) {
    margin-top: ${({ noBreadcrumbMargin }) => noBreadcrumbMargin && spacing.l};
    border-radius: 5px;
  }
`;
