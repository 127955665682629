import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const MapMarkerDistanceIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7.5" cy="8.5" r="2" stroke="#DC0032" strokeLinecap="round" />
    <path
      d="M14.5 8.7381C14.5 14.1667 7.5 20.5 7.5 20.5C7.5 20.5 0.5 14.1667 0.5 8.7381C0.5 4.74061 3.63401 1.5 7.5 1.5C11.366 1.5 14.5 4.74061 14.5 8.7381Z"
      stroke="#DC0032"
      strokeLinecap="round"
    />
    <circle cx="24.5" cy="8.5" r="2" stroke="#414042" strokeLinecap="round" />
    <path
      d="M31.5 8.7381C31.5 14.1667 24.5 20.5 24.5 20.5C24.5 20.5 17.5 14.1667 17.5 8.7381C17.5 4.74061 20.634 1.5 24.5 1.5C28.366 1.5 31.5 4.74061 31.5 8.7381Z"
      stroke="#414042"
      strokeLinecap="round"
    />
    <path d="M11.5 28.5L22.5 28.5" stroke="#414042" strokeLinecap="round" />
    <ellipse
      cx="7.5"
      cy="28.5"
      rx="2"
      ry="2"
      fill="#DC0032"
      stroke="#DC0032"
      strokeLinecap="round"
    />
    <circle cx="24.5" cy="28.5" r="2" stroke="#414042" strokeLinecap="round" />
  </svg>
);
