import { Link } from '@hp/atomic';
import { useConfig } from '@hp/config';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { Trans } from '@lingui/react';
import React from 'react';

import {
  Column,
  Item,
  ItemWrapper,
  Logo,
  LogoWrapper,
  Row,
  Wrapper,
  Year,
} from './styled';

export const Footer = () => {
  const year = new Date().getFullYear();
  const { language } = useLanguage();
  const {
    config: { externalLinks },
  } = useConfig();
  const links = externalLinks[language] ?? externalLinks.default;

  return (
    <Wrapper>
      <Row>
        <LogoWrapper>
          <Logo />
          <Year hideOnDesktop>{year}</Year>
        </LogoWrapper>
        <ItemWrapper>
          <Column>
            <Item>
              <Link
                className="gaLink gaLink_faq"
                href={links.faq}
                target="_blank"
              >
                <Trans id="navigation.contacts">
                  Nejčastější dotazy a kontakty
                </Trans>
              </Link>
            </Item>
            <Item>
              <Link
                className="gaLink gaLink_howToPack"
                href={links.howWrap}
                target="_blank"
              >
                <Trans id="navigation.howToPack">Jak zabalit zásilku</Trans>
              </Link>
            </Item>
          </Column>

          <Column>
            <Item>
              <Link
                className="gaLink gaLink_privacy"
                href={links.privacyPolicy}
                target="_blank"
              >
                <Trans id="legal.privacy.title">Ochrana osobních údajů</Trans>
              </Link>
            </Item>
            <Item>
              <Link
                className="gaLink gaLink_terms"
                href={links.termsConditions}
                target="_blank"
              >
                <Trans id="legal.terms.title">
                  Všeobecné obchodní podmínky
                </Trans>
              </Link>
            </Item>
            <Item>
              <Link
                className="gaLink gaLink_cookies"
                href={links.cookies}
                target="_blank"
              >
                <Trans id="legal.cookies.title">
                  Naše zásady používání cookies
                </Trans>
              </Link>
            </Item>
          </Column>
        </ItemWrapper>
      </Row>
    </Wrapper>
  );
};
