import { breakpoints, colors, spacing, typography } from '@hp/theme';
import React, { FC } from 'react';
import styled from 'styled-components';

type H1Props = {
  id?: string;
  className?: string;
  color?: string;
  marginSize?: Extract<keyof typeof spacing, 'm' | 'l'>;
  textAlign?: 'center' | 'left' | 'right';
};

const H1Styled = styled.h1<Pick<H1Props, 'color' | 'marginSize' | 'textAlign'>>`
  font-size: ${typography.fontSize.h1};
  line-height: ${typography.lineHeight.h1};
  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};
  margin: ${({ marginSize }) => marginSize && spacing[marginSize]} 0;

  @media (min-width: ${breakpoints.minDesktop}) {
    font-size: ${typography.fontSize.desktop.h1};
  }
`;

export const H1: FC<H1Props> = ({
  id,
  className,
  color = colors.black,
  marginSize = 'm',
  textAlign = 'left',
  children,
}) => {
  const commonProps = { className, color, marginSize, textAlign };

  return (
    <H1Styled id={id} {...commonProps}>
      {children}
    </H1Styled>
  );
};
