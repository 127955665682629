import { config as appConfig, Environment } from '@hp/config';
import { DynamicConfigRoot } from '@hp/core/shared';
// import { features } from '@hp/core/shared';
import { getKey, hasFeature, safeLocalStorage } from '@hp/utils/';

import { RouteNameType, RouteProps, RoutesType } from './types';
import { getQueryStr } from './utils';

const isProd = appConfig.app.environment === Environment.PRODUCTION;
const hasKey = (key: string) => safeLocalStorage.getItem(getKey(key));
const noSsr = true;
const auth = true;

/** shorthand for creating function to be used in routes. This function checks local storage for given keys.
 * If any value is not available, returns given fallback route */
const withLocalStorageKeys = (
  requiredKeys: string[],
  fallback: RouteNameType = 'landing',
) => {
  const requirements: RouteProps['requirements'] = ({ ssr }) => {
    if (ssr) return;
    const missingData = requiredKeys.map(hasKey).some((value) => !value);
    if (missingData) return fallback;
  };
  return requirements;
};

/** shorthand for creating function to be used in routes. This function checks features for given keys.*/
const withFeatures = (
  requiredFeatures: (keyof DynamicConfigRoot['features'])[],
  fallback: RouteNameType = 'landing',
) => {
  return () => {
    const isAllowed = requiredFeatures.every(hasFeature);
    if (!isAllowed) return fallback;
  };
};

/** this function checks, if uses was authenticated */
const withAuth = (fallback: RouteNameType = 'landing') => {
  const requirements: RouteProps['requirements'] = ({
    ssr,
    user,
    routeProps: { auth, noSsr },
  }) => {
    if (!auth)
      throw new Error(
        'BAD configuration! When withAuth is called, route.auth must be TRUE !!!',
      );
    if (!noSsr)
      throw new Error(
        'BAD configuration! When withAuth is called, route.noSsr must be TRUE !!!',
      );
    if (ssr) return;
    if (!user) return fallback;
  };
  return requirements;
};
/** when user is signed-in, returns fallback */
const withoutAuth = (fallback: RouteNameType = 'accountHomepage') => {
  const requirements: RouteProps['requirements'] = ({
    ssr,
    user,
    routeProps: { auth, noSsr },
  }) => {
    if (!auth)
      throw new Error(
        'BAD configuration! When withoutAuth is called, route.auth must be TRUE !!!',
      );
    if (!noSsr)
      throw new Error(
        'BAD configuration! When withoutAuth is called, route.noSsr must be TRUE !!!',
      );
    if (ssr) return;

    if (user) return fallback;
  };
  return requirements;
};

const combine = (...funs: RouteProps['requirements'][]) => {
  const agregF: RouteProps['requirements'] = (args) => {
    for (const fun of funs) {
      const fallbackPage = fun(args);
      if (fallbackPage) return fallbackPage;
    }
  };
  return agregF;
};

export const routes: RoutesType = {
  landing: {
    href: '/',
    noSsr,
    auth: true,
    nextPages: ['orderParcel', 'orderDelivery'],
    requirements: withoutAuth(),
  },
  orderDelivery: {
    href: '/order/delivery',
    noSsr,
    requirements: withLocalStorageKeys(['howToSend']),
    nextPages: 'orderRecipient',
  },
  orderParcel: {
    href: '/order/parcel',
    noSsr,
    requirements: withLocalStorageKeys(['howToSend']),
    nextPages: () => (hasFeature('v3') ? 'orderDelivery' : 'orderRecipient'),
  },
  orderRecipient: {
    href: '/order/recipient',
    noSsr,
    requirements: withLocalStorageKeys(['howToSend', 'parcel']),
    nextPages: ['orderSender', 'orderAdditionalServices'],
  },
  orderSender: {
    href: '/order/sender',
    noSsr,
    auth: true,
    requirements: combine(
      withLocalStorageKeys(['howToSend', 'parcel', 'recipient']),
    ),
  },
  orderAdditionalServices: {
    href: '/order/additional-services',
    noSsr,
    requirements: withLocalStorageKeys([
      'howToSend',
      'parcel',
      'recipient',
      'sender',
    ]),
  },
  orderSummary: {
    href: ({
      id,
      mode,
      ...rest /* some params can append payment gateway */
    }) =>
      id
        ? `/order/summary?${getQueryStr({ id, mode, ...rest })}`
        : '/order/summary',
    noSsr,
    requirements: (args) => {
      return args.query?.id
        ? //not required when Id is provided
          null
        : withLocalStorageKeys([
            'howToSend',
            'parcel',
            'recipient',
            'sender',
            'additionalServices',
          ])(args);
    },
  },
  thankYou: {
    href: '/order/thank-you/[id]',
    asHref: (id: string) => `/order/thank-you/${id}`,
    noSsr,
    auth,
  },
  register: {
    href: '/register',
    noSsr,
    auth,
    requirements: withoutAuth(),
  },
  registerLanding: {
    href: '/register/[campaign]',
    asHref: (campaign: string) => `/register/${campaign}`,
    noSsr,
    auth,
    requirements: withoutAuth(),
  },
  login: {
    href: ({ redirectTo }) =>
      redirectTo ? `/login?redirectTo=${redirectTo}` : '/login',
    noSsr,
    auth,
    requirements: (args) => {
      /* when redirect mode, fallback is not aplied; redirect is handled by form itself */
      if (!!args.query?.redirectTo) return null;
      return withoutAuth()(args);
    },
    nextPages: 'accountHomepage',
  },

  resetPassword: {
    href: '/reset-password',
    auth,
    noSsr,
    requirements: withoutAuth('accountHomepage'),
  },
  termsAndConditions: {
    href: '/legal/terms-and-conditions',
  },
  privacyPolicy: {
    href: '/legal/privacy-policy',
  },
  accountHomepage: {
    href: '/account',
    auth,
    noSsr,
    requirements: withAuth(),
    nextPages: ['orderParcel', 'orderDelivery'],
  },
  accountHomepageSend: {
    href: '/account#send',
    auth,
    noSsr,
    requirements: withAuth(),
  },
  accountProfile: {
    href: '/account/profile',
    auth,
    noSsr,
    requirements: withAuth(),
  },
  accountProfileAddress: {
    href: ({ id }) =>
      id ? `/account/profile/address?id=${id}` : '/account/profile/address',
    auth,
    noSsr,
    requirements: withAuth(),
  },
  accountProfileMainInfo: {
    href: '/account/profile/main',
    auth,
    noSsr,
    requirements: withAuth(),
  },
  accountProfileBankAccount: {
    href: '/account/profile/bank-account',
    auth,
    noSsr,
    requirements: withAuth(),
  },
  accountProfileChangePassword: {
    href: '/account/profile/change-password',
    auth,
    noSsr,
    requirements: withAuth(),
  },
  accountProfileDeleteAccount: {
    href: '/account/profile/delete-account',
    auth,
    noSsr,
    requirements: withAuth(),
  },
  accountProfileAccountDeleted: {
    href: '/account/profile/account-deleted',
    auth,
    noSsr,
  },
  accountArchive: {
    href: '/account/archive',
    auth,
    noSsr,
    requirements: withAuth(),
  },
  accountArchiveDetail: {
    href: '/account/archive/[id]',
    asHref: (id: string) => `/account/archive/${id}`,
    auth,
    noSsr,
    requirements: withAuth(),
  },
  accountParcelShops: {
    href: '/account/parcelshops',
    auth,
    noSsr,
    requirements: combine(
      withFeatures(['parcelShopsMapIntegration']),
      withAuth(),
    ),
  },
  adminMessages: {
    href: '/admin/messages',
    requirements: () => (isProd ? 'landing' : null),
  },
  adminFeatures: {
    href: '/admin/features',
    requirements: () => (isProd ? 'landing' : null),
  },
  adminConfig: {
    href: '/admin/config',
    noSsr,
    auth,
  },
  error404: {
    href: '/404',
  },
  langEn: {
    href: '/en',
  },
  langCs: {
    href: '/cs',
  },
};

export enum UtmQueryParams {
  utm_source = 'utm_source',
  utm_medium = 'utm_medium',
  utm_campaign = 'utm_campaign',
  utm_content = 'utm_content',
}
