import { breakpoints, colors, elements, misc, spacing } from '@hp/theme';
import styled, { css } from 'styled-components';

export const BorderPanelCss = css<{ checked?: boolean }>`
  position: relative;
  min-height: ${elements.buttonHeight};
  padding: ${({ checked }) => (checked ? '7px 2px' : '8px 3px')};

  @media (min-width: ${breakpoints.midMobile}) {
    padding: ${({ checked }) => (checked ? '7px 6px' : '8px 7px')};
  }

  @media (min-width: ${breakpoints.minDesktop}) {
    min-height: 70px;
    padding: ${({ checked }) => (checked ? '7px 19px' : '8px 20px')};
  }

  background-color: ${colors.white};
  border: ${({ checked }) =>
    checked
      ? '2px solid ' + colors.red_main
      : '1px solid ' + colors.gray_light};
  border-radius: 5px;
  box-shadow: ${({ checked }) => (checked ? misc.shadow_button : 'none')};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: border-color 0.3s;
`;

export const BorderPanel = styled.div`
  ${BorderPanelCss};
`;

export const BorderPanelIconButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 1px;
  right: 1px;
  height: 40px;
  width: 40px;
  background: transparent;
  padding: ${spacing.s};
  outline: none;
  border: none;
  border-radius: 50%;
  margin: ${spacing.s};
  transition: background-color 0.3s;

  :hover {
    background-color: ${colors.gray_light};
    border-radius: 50%;
  }
`;
