import { breakpoints, spacing } from '@hp/theme';
import styled, { css } from 'styled-components';

type ColumnProps = {
  fullWidth?: boolean;
};

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex: auto;
  flex-direction: column;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
`;

type Column2Props = {
  hideOnMobile?: boolean;
  hideOnDesktop?: boolean;
  centered?: boolean;
  withSpacing?: boolean;
  smallerFlex?: boolean;
};

export const Column2 = styled.div<Column2Props>`
  display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'flex')};
  flex-direction: column;
  flex: ${({ smallerFlex }) => (smallerFlex ? '25%' : '50%')};

  ${({ centered }) =>
    centered &&
    css`
      align-items: center;
      justify-content: center;
    `};

  ${({ withSpacing }) =>
    withSpacing &&
    css`
      flex: 45%;

      :first-child {
        margin-right: ${spacing.s};
      }

      :last-child {
        margin-left: ${spacing.s};
      }
    `};

  @media (min-width: ${breakpoints.minDesktop}) {
    display: ${({ hideOnDesktop }) => (hideOnDesktop ? 'none' : 'flex')};

    ${({ withSpacing }) =>
      withSpacing &&
      css`
        flex: 45%;

        :first-child {
          margin-right: ${spacing.s};
        }

        :last-child {
          margin-left: ${spacing.s};
        }
      `};
  }
`;

export const ColumnResponsive = styled.div`
  display: flex;
  flex-direction: row;
  flex: 100%;
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.minDesktop}) {
    flex-direction: column;
    flex: 50%;
  }
`;
