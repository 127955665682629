import { colors } from '@hp/theme';
import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
  circleColor?: string;
};

export const QuestionIcon: FC<Props> = ({
  className,
  color = colors.red_main,
  circleColor = colors.black,
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13.351 18.3529C15.2835 17.5776 16.8873 16.1563 17.8894 14.331C18.8914 12.5058 19.2295 10.3896 18.8461 8.34297C18.4628 6.29637 17.3816 4.44601 15.7869 3.10716C14.1922 1.76832 12.1827 1.02384 10.1006 1.00056C8.01852 0.977287 5.9928 1.67666 4.36857 2.97952C2.74435 4.28237 1.62212 6.1081 1.1931 8.14562C0.764076 10.1831 1.05481 12.3064 2.01576 14.1536C2.97672 16.0008 4.54843 17.4576 6.4631 18.2759"
      stroke={circleColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 14.45C9.5 14.1739 9.72386 13.95 10 13.95C10.2761 13.95 10.5 14.1739 10.5 14.45H9.5ZM10.5 14.5C10.5 14.7761 10.2761 15 10 15C9.72386 15 9.5 14.7761 9.5 14.5H10.5ZM10.5 14.45V14.5H9.5V14.45H10.5Z"
      fill={color}
    />
    <path
      d="M7.5 7.00001C8.5 4.50008 12.5 5.00009 12.5 7.50009C12.5 9.5 10.7 10 10.2 11C10.0211 11.3578 10 11.5 10 12"
      stroke={color}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
