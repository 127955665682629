import { t } from '@lingui/macro';

import { MetaType } from './types';

export const meta: MetaType = {
  default: {
    title: t(
      'meta.title.default',
    )`Jednoduché posílání balíků z ruky do ruky | DPD`,
    tags: {
      description: t(
        'meta.description.default',
      )`Posílejte balíky pohodlně a bez starostí. Všechno zvládnete online, bez tisku a zásilku u vás vyzvedneme nebo ji přinesete na naši pobočku.`,
      keywords: t(
        'meta.keywords.default',
      )`Balík, jak poslat zásilku, přeprava po ČR, kurýr`,
    },
  },
  orderDelivery: {
    title: t('meta.title.orderDelivery')`Místo odeslání a doručení | DPD`,
    tags: {
      description: t(
        'meta.description.orderDelivery',
      )`Posílejte balíky pohodlně a bez starostí. Všechno zvládnete online, bez tisku a zásilku u vás vyzvedneme nebo ji přinesete na naši pobočku.`,
      keywords: t(
        'meta.keywords.orderDelivery',
      )`Balík, jak poslat zásilku, přeprava po ČR, kurýr`,
    },
  },
  orderParcel: {
    title: t('meta.title.orderParcel')`Rozměry zásilky a cena | DPD`,
    tags: {
      description: t(
        'meta.description.orderParcel',
      )`Přepravujeme malé i velké zásilky do 31 kg. Už od 79 Kč s doručením následující pracovní den.`,
      keywords: t(
        'meta.keywords.orderParcel',
      )`Balík, jak poslat zásilku, přeprava po ČR, kurýr`,
    },
  },
  orderRecipient: {
    title: t(
      'meta.title.orderRecipient',
    )`Doručení příjemci do druhého dne | DPD`,
    tags: {
      description: t(
        'meta.description.orderRecipient',
      )`Příjemce zásilky budeme o doručení informovat a sám si pak může změnit termín nebo adresu. Zadarmo a na pár kliknutí v aplikaci DPD Kurýr.`,
      keywords: t(
        'meta.keywords.orderRecipient',
      )`Balík, jak poslat zásilku, přeprava po ČR, kurýr`,
    },
  },
  orderSender: {
    title: t(
      'meta.title.orderSender',
    )`Vyzvednutí u odesílatele nebo podání na pobočce | DPD`,
    tags: {
      description: t(
        'meta.description.orderSender',
      )`Ať posíláte zásilku jednou za čas nebo každý týden, můžete to udělat jednoduše. Přeprava s DPD je spolehlivá, rychlá a bez emisí.`,
      keywords: t(
        'meta.keywords.orderSender',
      )`Balík, jak poslat zásilku, přeprava po ČR, kurýr`,
    },
  },
  orderAdditionalServices: {
    title: t(
      'meta.title.orderAdditionalServices',
    )`Dobírka a pojištění k balíku | DPD`,
    tags: {
      description: t(
        'meta.description.orderAdditionalServices',
      )`Potřebujete k zásilce dobírku, vyšší pojištění nebo ji označit jako křehkou? Vyberte si z našich doplňkových služeb a získejte přepravu na míru.`,
      keywords: t(
        'meta.keywords.orderAdditionalServices',
      )`Balík, jak poslat zásilku, přeprava po ČR, kurýr`,
    },
  },
  orderSummary: {
    title: t('meta.title.orderSummary')`Shrnutí objednávky přepravy | DPD`,
    tags: {
      description: t(
        'meta.description.orderSummary',
      )`Objednávku přepravy zaplatíte online platební kartou a daňový doklad vám pošleme e-mailem.`,
      keywords: t(
        'meta.keywords.orderSummary',
      )`Balík, jak poslat zásilku, přeprava po ČR, kurýr`,
    },
  },
  thankYou: {
    title: t('meta.title.thankYou')`Potvrzení objednávky | DPD`,
    tags: {
      description: t(
        'meta.description.thankYou',
      )`Po zaplacení vám zašleme e-mail s tipy jak zabalit zásilku a instrukcemi pro předání kurýrovi nebo pro odeslání z pobočky.`,
      keywords: t(
        'meta.keywords.thankYou',
      )`Balík, jak poslat zásilku, přeprava po ČR, kurýr`,
    },
  },
};
