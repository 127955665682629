import { breakpoints } from '@hp/theme';
import styled from 'styled-components';

type Props = {
  notFullWidth?: boolean;
};

export const HideOnMobile = styled.div<Props>`
  display: none;
  width: ${({ notFullWidth }) => (notFullWidth ? 'fit-content' : '100%')};

  @media (min-width: ${breakpoints.minDesktop}) {
    display: initial;
  }
`;

export const HideOnDesktop = styled.div<Props>`
  width: ${({ notFullWidth }) => (notFullWidth ? 'fit-content' : '100%')};

  @media (min-width: ${breakpoints.minDesktop}) {
    display: none;
  }
`;
