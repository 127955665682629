import React, { FC } from 'react';

type Props = {
  className?: string;
  zoom?: number;
  size: 'S' | 'M' | 'L' | 'XL';
};

const data = {
  S: (_zoom) => ({
    size: {
      x: 85,
      y: 31,
    },
    textStyle: {
      left: '15px',
      top: '0px',
    },
    content: (
      <>
        <defs>
          <linearGradient
            id="paint0linear"
            x1="47.2222"
            y1="13.3148"
            x2="37.7975"
            y2="38.1708"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#D0D0D0" />
          </linearGradient>
          <linearGradient
            id="paint1linear"
            x1="85"
            y1="28.9951"
            x2="62.1359"
            y2="-2.40763"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DFDFDF" />
            <stop offset="1" stopColor="#C2C2C2" />
          </linearGradient>
          <linearGradient
            id="paint2linear"
            x1="85"
            y1="9.86919"
            x2="82.1563"
            y2="-10.6275"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#D0D0D0" />
          </linearGradient>
        </defs>
        <path
          d="M47.7469 30.4321L0 22.037V4.1975L47.7469 10.4938V30.4321Z"
          fill="url(#paint0linear)"
        />
        <path
          d="M47.7469 30.4317L85 24.1354V6.29593L47.7469 10.4935V30.4317Z"
          fill="url(#paint1linear)"
        />
        <path
          d="M1.52588e-05 4.19753L37.2531 0L85 6.2963L47.2222 10.4938L1.52588e-05 4.19753Z"
          fill="url(#paint2linear)"
        />
        <path opacity="0.2" d="M61 9L13 3L24.5 2L72 8L61 9Z" fill="#414042" />
        <path opacity="0.2" d="M61 20.5V9L72 8V18.5L61 20.5Z" fill="#414042" />
        <path
          d="M1.31738 24.1801L47.0186 32.1512"
          stroke="#DC0032"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49 13L49 30"
          stroke="#DC0032"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.2701 32.1512L85.8118 25.8371"
          stroke="#DC0032"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
  }),
  M: (_zoom) => ({
    size: { x: 85, y: 53 },
    textStyle: {
      left: '12px',
      top: '8px',
    },
    content: (
      <>
        <defs>
          <linearGradient
            id="paint0linear"
            x1="47.2222"
            y1="28.3332"
            x2="22.6749"
            y2="63.5176"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#D0D0D0" />
          </linearGradient>
          <linearGradient
            id="paint1linear"
            x1="85"
            y1="49.7208"
            x2="41.4521"
            y2="18.456"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DFDFDF" />
            <stop offset="1" stopColor="#C2C2C2" />
          </linearGradient>
          <linearGradient
            id="paint2linear"
            x1="85"
            y1="8.88227"
            x2="82.6883"
            y2="-9.63121"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#D0D0D0" />
          </linearGradient>
        </defs>
        <path
          d="M47.7469 52.469L0 44.0739V4.19736L47.7469 9.44428V52.469Z"
          fill="url(#paint0linear)"
        />

        <path
          d="M47.7469 52.4692L85 46.1729V6.29635L47.7469 9.4445V52.4692Z"
          fill="url(#paint1linear)"
        />
        <path
          d="M1.52588e-05 4.19753L37.2531 0L85 6.2963L47.2222 9.44444L1.52588e-05 4.19753Z"
          fill="url(#paint2linear)"
        />
        <path
          opacity="0.2"
          d="M61.5 8L12 3L23.5 2L72.5 7L61.5 8Z"
          fill="#414042"
        />
        <path opacity="0.2" d="M61 28.5V9L72 8V27.5L61 28.5Z" fill="#414042" />

        <path
          d="M1.31738 46.1801L47.0186 54.1512"
          stroke="#DC0032"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49 13L49 52"
          stroke="#DC0032"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.2701 54.1512L85.8118 47.8371"
          stroke="#DC0032"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
  }),
  L: (_zoom) => ({
    size: { x: 85, y: 94 },
    textStyle: {
      left: '12px',
      top: '8px',
    },
    content: (
      <>
        <defs>
          <linearGradient
            id="paint0linear"
            x1="47.2222"
            y1="48.2716"
            x2="4.46428e-08"
            y2="84.4754"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#D0D0D0" />
          </linearGradient>
          <linearGradient
            id="paint1linear"
            x1="85"
            y1="88.1481"
            x2="27.1826"
            y2="66.405"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DFDFDF" />
            <stop offset="1" stopColor="#C2C2C2" />
          </linearGradient>
          <linearGradient
            id="paint2linear"
            x1="85"
            y1="7.89536"
            x2="83.1676"
            y2="-8.61428"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#D0D0D0" />
          </linearGradient>
        </defs>
        <path
          d="M47.7469 93.3951L0 85V3.14819L47.7469 8.39511V93.3951Z"
          fill="url(#paint0linear)"
        />
        <path
          d="M47.7469 93.395L85 87.0987V5.24683L47.7469 8.39497V93.395Z"
          fill="url(#paint1linear)"
        />
        <path
          d="M0 3.14815L37.2531 0L85 5.24691L47.2222 8.39506L0 3.14815Z"
          fill="url(#paint2linear)"
        />
        <path opacity="0.2" d="M62 7L14 2L25.5 1L73 6L62 7Z" fill="#414042" />
        <path opacity="0.2" d="M62 26.5V7L73 6V24.5L62 26.5Z" fill="#414042" />
        <path
          d="M1.31738 86.1801L47.0186 94.1512"
          stroke="#DC0032"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49 13L49 92"
          stroke="#DC0032"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.2701 94.1512L85.8118 87.8371"
          stroke="#DC0032"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
  }),
  XL: (zoom) => ({
    size: { x: 129, y: 55 },
    textStyle: {
      transform: 'rotate(-9deg)',
      left: `${40 + 20 * zoom * zoom}px`,
      top: `${3 + 4 * zoom * zoom}px`,
    },
    content: (
      <>
        <defs>
          <linearGradient
            id="paint0linear"
            x1="128"
            y1="51.0238"
            x2="84.8259"
            y2="-23.5072"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DFDFDF" />
            <stop offset="1" stopColor="#C2C2C2" />
          </linearGradient>
          <linearGradient
            id="paint1linear"
            x1="128"
            y1="16.9286"
            x2="122.434"
            y2="-18.0195"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#D0D0D0" />
          </linearGradient>
          <linearGradient
            id="paint2linear"
            x1="30.6703"
            y1="33.5"
            x2="8.49312"
            y2="57.0146"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#D0D0D0" />
          </linearGradient>
        </defs>
        <path
          d="M31 17.5417V54L79.5 45.4062L128 36.8125V4L31 17.5417Z"
          fill="url(#paint0linear)"
        />
        <path
          d="M1.00001 13.3714L93.1271 0L128 4.11429L31.1885 18L1.00001 13.3714Z"
          fill="url(#paint1linear)"
        />
        <path d="M31 54L1 46.3025V13L31 17.811V54Z" fill="url(#paint2linear)" />
        <path
          opacity="0.2"
          d="M23 16L115 2.5L103.5 1L12 14L23 16Z"
          fill="#414042"
        />
        <path
          opacity="0.2"
          d="M22.5 30.5V17L11.5 15V28.5L22.5 30.5Z"
          fill="#414042"
        />
        <path
          d="M31 54L1 46.3025V13L31 17.811V54Z"
          stroke="#DC0032"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.9021 18.173L127.998 4.15373"
          stroke="#DC0032"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
  }),
};

export const BoxIcon2Raw: FC<Props> = ({
  className,
  size,
  children,
  zoom = 1,
}) => {
  const {
    content,
    size: { x, y },
    textStyle,
  } = data[size](zoom);
  return (
    <div
      style={{
        position: 'relative',
        width: `${x * zoom + 10}px`,
        height: `${y * zoom + 10}px`,
      }}
      className={className}
    >
      <svg
        width={x * zoom + 0}
        height={y * zoom + 0}
        viewBox={`${0} 0 ${x + 2} ${y + 5}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {content}
      </svg>
      {children && (
        <div
          style={{
            position: 'absolute',
            color: '#DC0032',
            ...textStyle,
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export const BoxIcon2: React.FC<Props> = ({ ...props }) => {
  return <BoxIcon2Raw {...props} />;
};
