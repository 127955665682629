import { DPDLogo } from '@hp/atomic';
import { breakpoints, colors, elements, spacing, typography } from '@hp/theme';
import styled from 'styled-components';

export const Wrapper = styled.footer`
  flex-shrink: 0;
  width: 100%;
  max-height: ${elements.footerMaxHeightMobile};
  background-color: ${colors.note};
  padding: 0 20px ${spacing.l};
  border-top: 1px solid ${colors.gray_light};
  margin-top: auto;

  @media (min-width: ${breakpoints.minDesktop}) {
    max-height: ${elements.footerMaxHeight};
    padding-bottom: 0;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.black};
  margin: 12px 0 16px;

  @media (min-width: ${breakpoints.minDesktop}) {
    align-items: flex-start;
    margin-top: 0;
  }
`;

export const Logo = styled(DPDLogo)`
  height: 32px;
  width: auto;
  margin-right: ${spacing.m};
`;

type YearProps = {
  hideOnDesktop?: boolean;
};

export const Year = styled.span<YearProps>`
  padding-top: 6px;

  @media (min-width: ${breakpoints.minDesktop}) {
    display: ${({ hideOnDesktop }) => hideOnDesktop && 'none'};
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: ${breakpoints.fullWidth};
  margin: auto;
  font-size: ${typography.fontSize.bodySmall};
  padding: 12px 0;

  @media (min-width: ${breakpoints.minDesktop}) {
    justify-content: flex-start;
    max-width: ${elements.innerElementMaxWidth};
    padding: 40px 0 64px;
  }
`;

export const Column = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 24px;

  @media (min-width: ${breakpoints.minDesktop}) {
    flex-direction: row;
    align-items: flex-start;
    width: fit-content;
  }
`;

export const Item = styled.div`
  color: ${colors.black};
  font-size: ${typography.fontSize.bodySmall};
  line-height: ${typography.fontSize.bodySmall};
  font-weight: ${typography.fontWeight.light};
  text-decoration: underline;
  padding: 10px 0;

  @media (min-width: ${breakpoints.minDesktop}) {
    padding: 0 10px;
    margin: 10px 0 20px 100px;
  }
`;
