import { colors } from '@hp/theme';
import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
  width?: number;
  height?: number;
};

export const ChevronNavBigIcon: FC<Props> = ({
  className,
  color = colors.black,
  width = 18,
  height = 32,
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 18 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.88867 1.77783L16.1109 16.0001L1.88867 30.2223"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
