import React, { FC } from 'react';

type Props = {
  className?: string;
  zoom?: number;
};

export const BoxIconEmpty: FC<Props> = ({ className, zoom = 1 }) => {
  return (
    <svg
      className={className}
      width={116 * zoom}
      height={81 * zoom}
      // viewBox="0 0 116 81"
      viewBox={`0 0 ${116 * zoom} ${81 * zoom}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="38.0714"
          y1="38.9174"
          x2="5.39281e-07"
          y2="68.1054"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#D0D0D0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="68.5288"
          y1="71.0669"
          x2="21.9154"
          y2="53.5373"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFDFDF" />
          <stop offset="1" stopColor="#C2C2C2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="68.5288"
          y1="6.36537"
          x2="67.0515"
          y2="-6.94498"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#D0D0D0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="84.8321"
          y1="61.5407"
          x2="65.0416"
          y2="89.907"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#D0D0D0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="115.289"
          y1="78.7839"
          x2="80.18"
          y2="53.5777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFDFDF" />
          <stop offset="1" stopColor="#C2C2C2" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="115.289"
          y1="45.8593"
          x2="113.425"
          y2="30.9334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#D0D0D0" />
        </linearGradient>
      </defs>
      <path
        d="M38.4944 75.2967L0 68.5284V2.53809L38.4944 6.76824V75.2967Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M38.4947 75.297L68.5288 70.2208V4.23047L38.4947 6.76856V75.297Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M0.000358582 2.53809L30.0344 0L68.5288 4.23015L38.0717 6.76824L0.000358582 2.53809Z"
        fill="url(#paint2_linear)"
      />
      <path
        opacity="0.2"
        d="M49.9855 5.64394L11.2871 1.61286L20.5586 0.806641L58.8539 4.83773L49.9855 5.64394Z"
        fill="#414042"
      />
      <path
        opacity="0.2"
        d="M49.9854 21.3644V5.64313L58.8537 4.83691V19.7519L49.9854 21.3644Z"
        fill="#414042"
      />
      <path
        d="M85.2551 80.9994L46.7607 74.2312V42.082L85.2551 46.3122V80.9994Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M85.255 80.9997L115.289 75.9236V43.7744L85.255 46.3125V80.9997Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M46.7606 42.0824L76.7947 38.6982L115.289 43.7744L84.832 46.3125L46.7606 42.0824Z"
        fill="url(#paint5_linear)"
      />
      <path
        opacity="0.2"
        d="M96.3428 45.1478L56.4351 41.1168L65.7066 40.3105L105.211 44.3416L96.3428 45.1478Z"
        fill="#414042"
      />
      <path
        opacity="0.2"
        d="M95.9395 61.6759V45.9547L104.808 45.1484V60.8697L95.9395 61.6759Z"
        fill="#414042"
      />
    </svg>
  );
};
