import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const EnterIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 16L20 24L12 16"
      stroke="#414042"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
