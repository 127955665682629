import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const CourierColorsBigIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="97"
    height="90"
    viewBox="0 0 97 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      rx="10.9564"
      ry="10.9564"
      transform="matrix(-1 0 0 1 21.9074 62.2601)"
      stroke="#414042"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <ellipse
      rx="10.9564"
      ry="10.9564"
      transform="matrix(-1 0 0 1 71.2111 62.2601)"
      stroke="#414042"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M35.0547 62.2601L59.7066 62.2601"
      stroke="#414042"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M84.3584 62.2601H95.3148V37.6082L76.1411 18.4346H67.9238L67.9238 51.3037"
      stroke="#414042"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M67.9238 19.4437V2H2.18555L2.18555 62.2601H10.4028"
      stroke="#414042"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M15.8809 29.391L54.2282 29.391"
      stroke="#DC0032"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M43.272 18.4346L54.2284 29.391L43.272 40.3473"
      stroke="#DC0032"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);
