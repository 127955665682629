import React, { FC } from 'react';

type Props = {
  className?: string;
  width?: string;
};

export const DeleteIcon: FC<Props> = ({ className, width = '20' }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 4.5H4.16667H17.5"
      stroke="#A90034"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 4.5V3.16667C6.5 2.72464 6.6756 2.30072 6.98816 1.98816C7.30072 1.67559 7.72464 1.5 8.16667 1.5H11.8333C12.2754 1.5 12.6993 1.67559 13.0118 1.98816C13.3244 2.30072 13.5 2.72464 13.5 3.16667V4.5M15.5 4.5V16.8333C15.5 17.2754 15.3244 17.6993 15.0118 18.0118C14.6993 18.3244 14.2754 18.5 14.1666 18.5H6.16667C5.72464 18.5 5.30072 18.3244 4.98816 18.0118C4.67559 17.6993 4.5 17.2754 4.5 16.8333V4.5H15.5Z"
      stroke="#A90034"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 9.1665V14.1665"
      stroke="#A90034"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 9.1665V14.1665"
      stroke="#A90034"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
