import { breakpoints } from '@hp/theme';
import styled from 'styled-components';

import { LocationIcon } from '../icons';

type WrapperProps = {
  height: number | '100%';
  desktopHeight: number | '100%';
};

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: ${({ height }) => height}px;
  position: relative;
  overflow: hidden;

  @media (min-width: ${breakpoints.minDesktop}) {
    height: ${({ desktopHeight }) =>
      desktopHeight === '100%'
        ? desktopHeight
        : desktopHeight
        ? `${desktopHeight}px`
        : '80vh'};
  }
`;

export const LocationIconStyled = styled(LocationIcon)`
  position: absolute;
  top: -50px;
  left: -25px;
  width: 50px;
  height: 50px;
`;
