import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const WarningIcon: FC<Props> = ({ className }) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M52.6927 52.7031H11.3073C10.2862 52.7031 9.37227 52.1751 8.86121 51.2913C8.35015 50.4075 8.3511 49.3506 8.86121 48.4668L29.5539 12.6259C30.064 11.7421 30.9789 11.2141 32 11.2141C33.0212 11.2141 33.9351 11.7421 34.4462 12.6259L55.1389 48.4668C55.649 49.3515 55.649 50.4085 55.1379 51.2913C54.6278 52.176 53.713 52.7031 52.6927 52.7031ZM32 13.0965C31.8363 13.0965 31.4297 13.1426 31.185 13.5671L10.4913 49.408C10.2457 49.8325 10.4094 50.2089 10.4913 50.3501C10.5732 50.4913 10.816 50.8207 11.3073 50.8207H52.6927C53.1831 50.8207 53.4259 50.4913 53.5078 50.3501C53.5897 50.2089 53.7534 49.8334 53.5078 49.408L32.8151 13.5671C32.5704 13.1426 32.1638 13.0965 32 13.0965Z"
      fill="#414042"
    />
    <path
      d="M32 45.312C32.5198 45.312 32.9412 44.8906 32.9412 44.3708C32.9412 43.851 32.5198 43.4297 32 43.4297C31.4802 43.4297 31.0588 43.851 31.0588 44.3708C31.0588 44.8906 31.4802 45.312 32 45.312Z"
      fill="#DC0032"
    />
    <path
      d="M32 41.5473C31.4805 41.5473 31.0588 41.1266 31.0588 40.6061V27.2536C31.0588 26.7341 31.4805 26.3125 32 26.3125C32.5195 26.3125 32.9412 26.7341 32.9412 27.2536V40.6061C32.9412 41.1256 32.5195 41.5473 32 41.5473Z"
      fill="#DC0032"
    />
  </svg>
);
