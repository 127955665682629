import React, { FC } from 'react';

type Props = {
  className?: string;
  width?: string;
};

export const MobileChatColorsIcon: FC<Props> = ({
  className,
  width = '20',
}) => (
  <svg
    className={className}
    width={width}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33325 2.08325C8.33325 1.75173 8.46495 1.43379 8.69937 1.19937C8.93379 0.964948 9.25173 0.833252 9.58325 0.833252H17.9166C18.2481 0.833252 18.566 0.964948 18.8005 1.19937C19.0349 1.43379 19.1666 1.75173 19.1666 2.08325V7.08325C19.1666 7.41477 19.0349 7.73271 18.8005 7.96714C18.566 8.20156 18.2481 8.33325 17.9166 8.33325H11.2499L8.33325 10.4166V2.08325Z"
      stroke="#DC0032"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3333 5.83325H10.8333"
      stroke="#DC0032"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6666 3.33325H10.8333"
      stroke="#DC0032"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.24992 2.5H3.74992C3.30789 2.5 2.88397 2.67559 2.57141 2.98816C2.25885 3.30072 2.08325 3.72464 2.08325 4.16667V17.5C2.08325 17.942 2.25885 18.3659 2.57141 18.6785C2.88397 18.9911 3.30789 19.1667 3.74992 19.1667H11.6666C12.1086 19.1667 12.5325 18.9911 12.8451 18.6785C13.1577 18.3659 13.3333 17.942 13.3333 17.5V10.4167"
      stroke="#414042"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.08325 5H6.24992"
      stroke="#414042"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.08325 16.25H13.3333"
      stroke="#414042"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
