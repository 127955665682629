import { colors, spacing } from '@hp/theme';
import styled from 'styled-components';

export const InfoBox = styled.div`
  display: inline-block;
  width: 100%;
  background-color: ${colors.input};
  padding: ${spacing.s};
  border-radius: 5px;
  margin: ${spacing.s} 0;
`;
