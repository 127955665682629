import {
  cleanObject,
  LocalStorageOptions,
  useObjectLocalStorage,
} from '@hp/utils';
import { SetStateAction } from 'react';

import { OtherData } from '../types';

/** hook for simply storing various data */
export const useOtherDataLocalStorage = (options?: LocalStorageOptions) => {
  const [other, setOther] = useObjectLocalStorage<OtherData>(
    'other',
    {},
    options,
  );
  const utils = {
    /** cleans data about previous order.
     * @param newData Optionally allows append a new data to the otherData.
     */
    cleanPreviousOrder: (newData?: OtherData) => {
      const cleaned = cleanObject(
        <OtherData>{
          ...(other ?? {}),
          caseId: undefined,
          parcelNumbers: undefined,
          payment: undefined,
          ...(newData ?? {}),
          tmpThxPageData: undefined,
        },
        null,
      );
      setOther(cleaned);
    },
    setProperty: <K extends keyof OtherData>(
      key: K,
      value: OtherData[K] | ((prev: OtherData[K] | null) => OtherData[K]),
    ) => {
      setOther((prev) => {
        const newValue = typeof value === 'function' ? value(prev[key]) : value;
        return cleanObject({ ...prev, [key]: newValue }, null);
      });
    },
  };

  const setOtherWithCleanup = (value: SetStateAction<OtherData>) => {
    setOther(
      typeof value === 'function'
        ? (prev) => cleanObject(value(prev), null)
        : cleanObject(value, null),
    );
  };

  return [other ?? {}, setOtherWithCleanup, utils] as const;
};
