import { breakpoints, colors, spacing, typography } from '@hp/theme';
import React, { FC } from 'react';
import styled from 'styled-components';

type H2Props = {
  id?: string;
  className?: string;
  color?: string;
  marginSize?: Extract<keyof typeof spacing, 'zero' | 'm' | 'l'>;
  textAlign?: 'center' | 'left' | 'right';
};

const H2Styled = styled.h2<Pick<H2Props, 'color' | 'marginSize' | 'textAlign'>>`
  font-size: ${typography.fontSize.h2};
  line-height: ${typography.lineHeight.h2};
  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};
  margin: ${({ marginSize }) => marginSize && spacing[marginSize]} 0;
  font-weight: 300;

  @media (min-width: ${breakpoints.minDesktop}) {
    font-size: ${typography.fontSize.desktop.h2};
  }
`;

export const H2: FC<H2Props> = ({
  id,
  className,
  color = colors.black,
  marginSize = 'm',
  textAlign = 'left',
  children,
}) => {
  const commonProps = { className, color, marginSize, textAlign };

  return (
    <H2Styled id={id} {...commonProps}>
      {children}
    </H2Styled>
  );
};
