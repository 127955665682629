import { RouteNameType, useRouter } from '@hp/seo';
import React, { FC, MouseEventHandler } from 'react';

import { Button, ButtonProps } from './Button';

export type LinkButtonProps = {
  to: RouteNameType;
  asHrefParam?: any;
  onClickOverride?: MouseEventHandler<HTMLButtonElement>;
} & Omit<ButtonProps, 'onClick'>;

export const LinkButton: FC<LinkButtonProps> = ({
  children,
  to,
  asHrefParam,
  onClickOverride,
  ...props
}) => {
  const router = useRouter();

  return (
    <Button
      type="button"
      onClick={
        !!onClickOverride
          ? onClickOverride
          : () => router.push(to, null, asHrefParam)
      }
      {...props}
    >
      {children}
    </Button>
  );
};
