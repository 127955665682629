import { colors } from '@hp/theme';
import React, { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
};

export const CloseIcon: FC<Props> = ({ className, color = colors.black }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.41531 8.00114L15.0027 2.41364C15.3932 2.02311 15.3932 1.38995 15.0027 0.999429C14.6122 0.608909 13.979 0.608915 13.5885 0.999443L8.00109 6.58694L2.41413 1.00009C2.0236 0.60957 1.39044 0.609577 0.999918 1.0001C0.609397 1.39063 0.609403 2.0238 0.999931 2.41432L6.58689 8.00116L1.00016 13.588C0.609642 13.9785 0.609649 14.6117 1.00018 15.0022C1.3907 15.3927 2.02387 15.3927 2.41439 15.0022L8.00111 9.41536L13.5885 15.0026C13.979 15.3931 14.6122 15.3931 15.0027 15.0026C15.3932 14.6121 15.3932 13.9789 15.0027 13.5884L9.41531 8.00114Z"
      fill={color}
    />
  </svg>
);
